import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import ButtonComp from '../ButtonComp/ButtonComp';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2)
    }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1)
    }
}))(MuiDialogActions);

export default function CustomizedDialogBox(props) {
    const handleClose = () => {
        props.handleUpload('');
    };

    const onAgreeConfirmationHandler = () => {
        props.handleUpload('upload');
    };

    const { initialData } = props.state;

    return (
        <div>
            <Dialog
                aria-labelledby="customized-dialog-title"
                open={initialData.userProfile.data.newPresenter}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Hold On
                </DialogTitle>
                <DialogContent>
                    <Typography gutterBottom>
                        We need to celebrate you! Tell me a little more about
                        yourself.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <ButtonComp
                        handleClick={onAgreeConfirmationHandler}
                        name={'Continue'}
                    />
                </DialogActions>
            </Dialog>
        </div>
    );
}
