import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    onTitleChange,
    onPresentationLengthChange
} from '../../redux/action/Index';
import { Link } from 'react-router-dom';
import ButtonComp from '../ButtonComp/ButtonComp';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import '../../style/components/Mediaoption.scss';

const theme = createMuiTheme({
    overrides: {
        // Style sheet name
        MuiToggleButtonGroup: {
            root: {
                // marginBottom: '30px',
                // border: '0.5px solid #F9FBFD !important',
            },
            groupedHorizontal: {
                '&:not(:first-child)': {
                    borderTopLeftRadius: 5,
                    borderBottomLeftRadius: 5
                },
                '&:not(:last-child)': {
                    borderTopRightRadius: 5,
                    borderBottomRightRadius: 5,
                    marginRight: '50px'
                }
            }
        },
        MuiToggleButton: {
            // Name of the rule
            root: {
                // Some CSS
                // border: 0,
                color: '#F9B041',
                height: 54,
                padding: '0 35px',
                background: 'white',
                outline: 'none',
                border: '1px solid #F9B041 !important',
                boxSizing: 'border-box',
                boxShadow: '0px 2px 10px -2px rgba(0, 0, 0, 0.16)',
                borderRadius: '5px',
                fontSize: '1.1em',
                cursor: 'pointer',
                // marginRight: '50px',
                textTransform: 'none',

                '&$selected': {
                    background: '#F9B041 !important',
                    color: 'white'
                    // border: '0.5px solid #F9FBFD !important',
                }
            }
        }
    }
});

class MediaRecordingOption extends Component {
    render() {
        const {
            state: { initialData }
        } = this.props;
        const { mediaoption } = this.props.ApplicationText;

        return (
            <div className="mediaoption">
                <p className="mediaoption__title">{mediaoption.text1}</p>
                <p className="mediaoption__subtitle"> {mediaoption.text2}</p>
                <div className="mediaoption__inputsection">
                    <input
                        value={initialData.videoTitle || ''}
                        onChange={(event) =>
                            this.props.onTitleChange(event.target.value)
                        }
                        className="mediaoption__input"
                        placeholder={mediaoption.text3}
                    />
                    <p className="mediaoption__tip">Tip: Specificity helps</p>
                </div>
                <p className="mediaoption__text8">{mediaoption.text8}</p>
                <ThemeProvider theme={theme}>
                    <ToggleButtonGroup
                        value={initialData.presentationLength}
                        exclusive
                        onChange={(event, val) => {
                            if (val) {
                                this.props.onPresentationLengthChange(val);
                            }
                        }}
                        aria-label="text alignment">
                        <ToggleButton value={15} aria-label="left aligned">
                            <p>15 min</p>
                        </ToggleButton>
                        <ToggleButton value={30} aria-label="centered">
                            <p>30 min</p>
                        </ToggleButton>
                        <ToggleButton value={45} aria-label="right aligned">
                            <p>45 min</p>
                        </ToggleButton>
                        <ToggleButton value={60} aria-label="justified">
                            <p>60 min</p>
                        </ToggleButton>
                    </ToggleButtonGroup>
                </ThemeProvider>
                <div className="mediaoption__buttonnext">
                    <Link to="/presentation-purpose">
                        <ButtonComp
                            id={'NextButtonAction'}
                            btnDisable={
                                initialData.videoTitle === '' ? true : false
                            }
                            name={'Next'}
                        />
                    </Link>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        state: state.reducer,
        ApplicationText: state.ApplicationText
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            onTitleChange,
            onPresentationLengthChange
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MediaRecordingOption);
