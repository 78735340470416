import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import OverviewTab from '../../components/OverviewTab'
import ReachTab from '../../components/ReachTab'
import ReviewsTab from '../../components/ReviewsTab'
import PresentationsTab from '../../components/PresentationsTab';
import { onPresentationTabHandleChange } from '../../redux/action/Index';
import { useParams } from 'react-router';
import PresentationToolBar from './PresentationToolBar';

const REVIEWS = 'reviews';
const REACH = 'reach';
const REPORTS = 'reports';
const PRESENTATIONS = 'presentations';

function TabPanel(props) {
    const { children, value, index, classes, name, ...other } = props;

    return (
        <div role={`tabpanel`} id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`} hidden={value !== name} {...other} className={(value === name) ? classes.root : ''}>
            {value === name ? (
                <Container className={classes.root}>
                    {children}
                </Container>
            ) : null}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        height: '100%',
        marginTop: 10,
        backgroundColor: theme.palette.background.paper,
    },
}));

const PresentationPanel = (props) => {
    const classes = useStyles();
    let { id } = useParams();

    return (
        <Fragment>
            <PresentationToolBar />
            <TabPanel value={id} index={0} classes={classes} name={REPORTS}>
                <OverviewTab {...props} />
            </TabPanel>
            <TabPanel value={id} index={1} classes={classes} name={REACH}>
                <ReachTab {...props} />
            </TabPanel>
            <TabPanel value={id} index={2} classes={classes} name={REVIEWS}>
                <ReviewsTab {...props} />
            </TabPanel>
            <TabPanel value={id} index={3} classes={classes} name={PRESENTATIONS}>
                <PresentationsTab {...props} />
            </TabPanel>
        </ Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        state: state.reducer,
        ApplicationText: state.ApplicationText
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        onPresentationTabHandleChange
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PresentationPanel);
