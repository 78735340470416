import initState from './InitState';
import {
    SET_DOCUMENT_PAGE_COUNT,
    ON_PAGE_CHANGE_CLICK,
    ON_SAVE_RECORDED_VIDEO,
    UPLOAD_FILE_TO_S3_SUCCESS,
    UPLOAD_FILE_TO_S3_START,
    UPLOAD_FILE_TO_S3_FAIL,
    PAGE_UPDATE,
    NO_CHANGE,
    S3_VIDEO_DATA,
    S3_VIDEO_UPLOAD_PROGRESS,
    ADD_NEW_KEYWORD,
    ON_REMOVE_KEYWORD,
    ADD_NEW_CATEGORY,
    ON_INPUT_HANDLE_CHANGE,
    ON_CONDITION_VARIABLE,
    ON_TITLE_CHANGE,
    ON_CATEGORY_PAGE_CHANGE,
    SET_PROFILE_PICTURE,
    PRESENTATION_DATA,
    GET_PRESENTATIONS_DATA_START,
    GET_PRESENTATIONS_DATA_SUCCESS,
    GET_PRESENTATIONS_DATA_FAIL,
    ON_PRESENTATION_LENGTH_CHANGE,
    SET_VIDEO_PREVIEW_TO_FALSE,
    RESET_RECORDING_STATE,
    ON_LEARNING_OBJECTIVES_CHANGE,
    ON_PRESENTATION_RESET,
    ON_REFERENCES_CHANGE,
    ON_CONFLICTS_CHANGE,
    GET_PRESENTATION_DATA_START,
    GET_PRESENTATION_DATA_SUCCESS,
    GET_PRESENTATION_DATA_FAIL,
    ON_PRESENTATION_INFO_RESET,
    UPDATE_PRESENTATION_INFO_START,
    UPDATE_PRESENTATION_INFO_FAIL,
    UPDATE_PRESENTATION_INFO_SUCCESS,
    UPDATE_PRESENTATION_INFO_RESET,
    ON_PAGE_CHANGE_TIMESTAMP_RECORD,
    ON_RESET_RECORDED_TIMESTAMP,
    ON_SET_RECORDED_VIDEO_DURATION,
    ON_RESET_RECORDED_VIDEO_DURATION,
    GET_USER_PROFILE_START,
    GET_USER_PROFILE_FAIL,
    GET_USER_PROFILE_SUCCESS,
    ON_SAVE_RECORDED_VIDEO_START,
    ON_SAVE_RECORDED_VIDEO_FAIL,
    ON_SAVE_RECORDED_VIDEO_SUCCESS,
    REMOVE_PRESENTATION_START,
    REMOVE_PRESENTATION_SUCCESS,
    REMOVE_PRESENTATION_FAIL,
    REMOVE_PRESENTATION_RESET,
    RESET_PDF_PAGE_NUMBER,
    ON_PRESENTATION_INFO_SUBMIT_START,
    ON_PRESENTATION_INFO_SUBMIT_SUCCESS,
    ON_PRESENTATION_INFO_SUBMIT_FAIL,
    ON_PRESENTATION_FILE_SELECTOR_ERROR,
    ON_PRESENTATION_FILE_SELECTOR_RESET,
    ON_STORE_PROFILE_PICTURE_START,
    ON_STORE_PROFILE_PICTURE_SUCCESS,
    ON_STORE_PROFILE_PICTURE_FAIL,
    ON_STORE_PROFILE_PICTURE_RESET,
    ON_STORE_PROFILE_START,
    ON_STORE_PROFILE_SUCCESS,
    ON_STORE_PROFILE_FAIL,
    ON_STORE_PROFILE_RESET,
    ON_UPLOAD_REFERENCE_FILE_START,
    ON_UPLOAD_REFERENCE_FILE_SUCCESS,
    ON_UPLOAD_REFERENCE_FILE_FAIL,
    ON_UPLOAD_REFERENCE_FILE_RESET,
    ON_UPLOAD_REFERENCE_FILE_PROGRESS_UPDATE,
    ON_UPLOAD_ALL_REFERENCE_FILES_START,
    ON_UPLOAD_ALL_REFERENCE_FILES_SUCCESS,
    ON_UPLOAD_ALL_REFERENCE_FILES_RESET,
    ON_DISCARD_UPLOADED_REFERENCE_FILES,
    ON_REMOVE_FILE_FROM_REFERENCE_FILES,
    ON_UPLOAD_PRESENTATION_FILE_START,
    ON_UPLOAD_PRESENTATION_FILE_SUCCESS,
    ON_UPLOAD_PRESENTATION_FILE_FAIL,
    ON_UPLOAD_PRESENTATION_FILE_RESET,
    ON_UPLOAD_PRESENTATION_FILE_PROGRESS_UPDATE,
    ON_UPLOAD_ALL_PRESENTATION_FILES_START,
    ON_UPLOAD_ALL_PRESENTATION_FILES_SUCCESS,
    ON_UPLOAD_ALL_PRESENTATION_FILES_RESET,
    ON_DISCARD_UPLOADED_PRESENTATION_FILES,
    ON_REMOVE_FILE_FROM_PRESENTATION_FILES
} from '../action/Index.js';
import { updateObject } from 'redux/reducers/utils';

// experiment reducer
export default function (state = initState, action = {}) {
    let newState = state;

    switch (action.type) {
        case ON_UPLOAD_PRESENTATION_FILE_START: {
            newState = updateObject(state, {
                initialData: updateObject(state.initialData, {
                    presentationFileUploading: updateObject(
                        state.initialData.presentationFileUploading,
                        {
                            error: null,
                            success: false,
                            loading: true,
                            data: updateObject(
                                state.initialData.presentationFileUploading
                                    .data,
                                {
                                    progress: 0,
                                    fileName: action.payload
                                }
                            )
                        }
                    ),
                    videoForPresentationData: null,
                    videoForPresentationName: null,
                    pdfData: '',
                    pdfFileName: ''
                })
            });
            break;
        }

        case ON_UPLOAD_PRESENTATION_FILE_SUCCESS: {
            let presentationSuccessData = {};
            if (action.payload.videoId) {
                presentationSuccessData.videoForPresentationData =
                    action.payload;
                presentationSuccessData.videoForPresentationName =
                    state.initialData.presentationFileUploading.data.fileName;
            } else {
                presentationSuccessData.pdfData = action.payload;
                presentationSuccessData.pdfFileName =
                    action.payload.pdfFileName;
            }

            newState = updateObject(state, {
                initialData: updateObject(state.initialData, {
                    presentationFileUploading: updateObject(
                        state.initialData.presentationFileUploading,
                        {
                            error: null,
                            success: true,
                            loading: false
                        }
                    ),
                    presentationFiles: [
                        ...state.initialData.presentationFiles,
                        {
                            fileId: action.payload.fileId,
                            fileKey: action.payload.fileKey,
                            fileName:
                                state.initialData.presentationFileUploading.data
                                    .fileName
                        }
                    ],
                    ...presentationSuccessData
                })
            });
            break;
        }

        case ON_UPLOAD_PRESENTATION_FILE_RESET: {
            newState = updateObject(state, {
                initialData: updateObject(state.initialData, {
                    presentationFileUploading: updateObject(
                        state.initialData.presentationFileUploading,
                        {
                            error: null,
                            success: false,
                            loading: false,
                            data: updateObject(
                                state.initialData.presentationFileUploading
                                    .data,
                                {
                                    progress: 0,
                                    fileName: null
                                }
                            )
                        }
                    )
                })
            });
            break;
        }

        case ON_UPLOAD_PRESENTATION_FILE_FAIL: {
            newState = updateObject(state, {
                initialData: updateObject(state.initialData, {
                    presentationFileUploading: updateObject(
                        state.initialData.presentationFileUploading,
                        {
                            error: action.payload,
                            success: false,
                            loading: false
                        }
                    ),
                    presentationFilesFailedToUpload: [
                        ...state.initialData.presentationFilesFailedToUpload,
                        {
                            fileName: action.payload.fileName,
                            error: action.payload.error
                        }
                    ]
                })
            });
            break;
        }

        case ON_UPLOAD_PRESENTATION_FILE_PROGRESS_UPDATE: {
            newState = updateObject(state, {
                initialData: updateObject(state.initialData, {
                    presentationFileUploading: {
                        error: action.payload,
                        success: false,
                        loading: false,
                        data: updateObject(
                            state.initialData.presentationFileUploading.data,
                            {
                                progress: action.payload
                            }
                        )
                    }
                })
            });
            break;
        }

        case ON_UPLOAD_ALL_PRESENTATION_FILES_START: {
            newState = updateObject(state, {
                initialData: updateObject(state.initialData, {
                    presentationFileUploadingStatus: {
                        loading: true,
                        success: false,
                        error: false
                    }
                })
            });
            break;
        }

        case ON_UPLOAD_ALL_PRESENTATION_FILES_SUCCESS: {
            newState = updateObject(state, {
                initialData: updateObject(state.initialData, {
                    presentationFileUploadingStatus: {
                        loading: false,
                        success: true,
                        error: false
                    }
                })
            });
            break;
        }

        case ON_UPLOAD_ALL_PRESENTATION_FILES_RESET: {
            newState = updateObject(state, {
                initialData: updateObject(state.initialData, {
                    presentationFileUploadingStatus: {
                        loading: false,
                        success: false,
                        error: false
                    },
                    presentationFiles: [],
                    presentationFilesFailedToUpload: []
                })
            });
            break;
        }

        case ON_DISCARD_UPLOADED_PRESENTATION_FILES: {
            newState = updateObject(state, {
                initialData: updateObject(state.initialData, {
                    presentationFileUploadingStatus: {
                        loading: false,
                        success: false,
                        error: false
                    },
                    presentationFiles: [],
                    presentationFilesFailedToUpload: []
                })
            });
            break;
        }

        case ON_REMOVE_FILE_FROM_PRESENTATION_FILES: {
            let updatedPresentationFiles = [
                ...state.initialData.presentationFiles
            ];
            updatedPresentationFiles = updatedPresentationFiles.filter(
                (file) => file.fileName !== action.payload
            );

            let updatedPresentationFilesFailedToUpload = [
                ...state.initialData.presentationFilesFailedToUpload
            ];
            updatedPresentationFilesFailedToUpload =
                updatedPresentationFilesFailedToUpload.filter(
                    (file) => file.fileName !== action.payload
                );

            newState = {
                ...state,
                initialData: {
                    ...state.initialData,
                    presentationFiles: updatedPresentationFiles,
                    presentationFilesFailedToUpload:
                        updatedPresentationFilesFailedToUpload
                }
            };
            break;
        }

        case ON_UPLOAD_REFERENCE_FILE_START: {
            newState = {
                ...state,
                initialData: {
                    ...state.initialData,
                    referenceFileUploading: {
                        ...state.initialData.referenceFileUploading,
                        error: null,
                        success: false,
                        loading: true,
                        data: {
                            ...state.initialData.referenceFileUploading.data,
                            progress: 0,
                            fileName: action.payload
                        }
                    }
                }
            };
            break;
        }

        case ON_UPLOAD_REFERENCE_FILE_SUCCESS: {
            newState = {
                ...state,
                initialData: {
                    ...state.initialData,
                    referenceFileUploading: {
                        ...state.initialData.referenceFileUploading,
                        error: null,
                        success: true,
                        loading: false
                    },
                    referenceFiles: [
                        ...state.initialData.referenceFiles,
                        {
                            fileId: action.payload.fileId,
                            fileKey: action.payload.fileKey,
                            fileName:
                                state.initialData.referenceFileUploading.data
                                    .fileName
                        }
                    ]
                }
            };
            break;
        }

        case ON_UPLOAD_REFERENCE_FILE_RESET: {
            newState = {
                ...state,
                initialData: {
                    ...state.initialData,
                    referenceFileUploading: {
                        ...state.initialData.referenceFileUploading,
                        error: null,
                        success: false,
                        loading: false,
                        data: {
                            ...state.initialData.referenceFileUploading.data,
                            progress: 0,
                            fileName: null
                        }
                    }
                }
            };
            break;
        }

        case ON_UPLOAD_REFERENCE_FILE_FAIL: {
            newState = {
                ...state,
                initialData: {
                    ...state.initialData,
                    referenceFileUploading: {
                        ...state.initialData.referenceFileUploading,
                        error: action.payload,
                        success: false,
                        loading: false
                    },
                    referenceFilesFailedToUpload: [
                        ...state.initialData.referenceFilesFailedToUpload,
                        {
                            fileName: action.payload.fileName,
                            error: action.payload.error
                        }
                    ]
                }
            };
            break;
        }

        case ON_UPLOAD_REFERENCE_FILE_PROGRESS_UPDATE: {
            newState = {
                ...state,
                initialData: {
                    ...state.initialData,
                    referenceFileUploading: {
                        error: action.payload,
                        success: false,
                        loading: false,
                        data: {
                            ...state.initialData.referenceFileUploading.data,
                            progress: action.payload
                        }
                    }
                }
            };
            break;
        }

        case ON_UPLOAD_ALL_REFERENCE_FILES_START: {
            newState = {
                ...state,
                initialData: {
                    ...state.initialData,
                    referenceFilesUploadingStatus: {
                        loading: true,
                        success: false,
                        error: false
                    }
                }
            };
            break;
        }

        case ON_UPLOAD_ALL_REFERENCE_FILES_SUCCESS: {
            newState = {
                ...state,
                initialData: {
                    ...state.initialData,
                    referenceFilesUploadingStatus: {
                        loading: false,
                        success: true,
                        error: false
                    }
                }
            };
            break;
        }

        case ON_UPLOAD_ALL_REFERENCE_FILES_RESET: {
            newState = {
                ...state,
                initialData: {
                    ...state.initialData,
                    referenceFilesUploadingStatus: {
                        loading: false,
                        success: false,
                        error: false
                    },
                    referenceFiles: [],
                    referenceFilesFailedToUpload: []
                }
            };
            break;
        }

        case ON_DISCARD_UPLOADED_REFERENCE_FILES: {
            newState = {
                ...state,
                initialData: {
                    ...state.initialData,
                    referenceFilesUploadingStatus: {
                        loading: false,
                        success: false,
                        error: false
                    },
                    referenceFiles: [],
                    referenceFilesFailedToUpload: []
                }
            };
            break;
        }

        case ON_REMOVE_FILE_FROM_REFERENCE_FILES: {
            let updatedReferenceFiles = [...state.initialData.referenceFiles];
            updatedReferenceFiles = updatedReferenceFiles.filter(
                (file) => file.fileName !== action.payload
            );

            let updatedReferenceFilesFailedToUpload = [
                ...state.initialData.referenceFilesFailedToUpload
            ];
            updatedReferenceFilesFailedToUpload =
                updatedReferenceFilesFailedToUpload.filter(
                    (file) => file.fileName !== action.payload
                );

            newState = {
                ...state,
                initialData: {
                    ...state.initialData,
                    referenceFiles: updatedReferenceFiles,
                    referenceFilesFailedToUpload:
                        updatedReferenceFilesFailedToUpload
                }
            };
            break;
        }

        case GET_USER_PROFILE_START: {
            newState = {
                ...state,
                initialData: {
                    ...state.initialData,
                    userProfile: {
                        ...state.initialData.userProfile,
                        loading: true,
                        success: false,
                        error: null
                    }
                }
            };
            break;
        }
        case GET_USER_PROFILE_FAIL: {
            newState = {
                ...state,
                initialData: {
                    ...state.initialData,
                    userProfile: {
                        ...state.initialData.userProfile,
                        loading: false,
                        success: false,
                        error: action.payload.error
                    }
                }
            };
            break;
        }
        case GET_USER_PROFILE_SUCCESS: {
            newState = {
                ...state,
                initialData: {
                    ...state.initialData,
                    userProfile: {
                        ...state.initialData.userProfile,
                        data: action.payload,
                        loading: false,
                        success: true,
                        error: null
                    }
                }
            };
            break;
        }
        case SET_DOCUMENT_PAGE_COUNT: {
            newState = { ...state };
            newState.initialData.docPageCount = action.payload.value;
            if (newState.initialData.docPageCount !== null) {
                newState.initialData.showVideo = true;
            }
            break;
        }
        case UPLOAD_FILE_TO_S3_START: {
            newState = {
                ...state,
                initialData: {
                    ...state.initialData,
                    uploadFileToS3: {
                        ...state.uploadFileToS3,
                        loading: true,
                        success: false,
                        error: false,
                        data: null
                    }
                }
            };

            break;
        }
        case UPLOAD_FILE_TO_S3_FAIL: {
            newState = {
                ...state,
                initialData: {
                    ...state.initialData,
                    uploadFileToS3: {
                        ...state.uploadFileToS3,
                        loading: false,
                        success: false,
                        error: action.payload.error,
                        data: null
                    },
                    fileLoader: false
                }
            };

            break;
        }
        case UPLOAD_FILE_TO_S3_SUCCESS: {
            newState = {
                ...state,
                initialData: {
                    ...state.initialData,
                    uploadFileToS3: {
                        ...state.initialData.uploadFileToS3,
                        loading: false,
                        success: true,
                        error: null,
                        data: action.payload
                    }
                }
            };
            newState.initialData.pdfData = action.payload.value;
            newState.initialData.pdfFileName = action.payload.pdfFileName;
            newState.initialData.fileLoader = false;
            break;
        }
        case GET_PRESENTATIONS_DATA_START: {
            newState = {
                ...state,
                initialData: {
                    ...state.initialData,
                    presentationsData: {
                        ...state.initialData.presentationsData,
                        data: [],
                        error: null,
                        success: false,
                        loading: true
                    }
                }
            };
            break;
        }
        case GET_PRESENTATIONS_DATA_FAIL: {
            newState = {
                ...state,
                initialData: {
                    ...state.initialData,
                    presentationsData: {
                        ...state.initialData.presentationsData,
                        data: [],
                        error: action.payload,
                        success: false,
                        loading: false
                    }
                }
            };
            break;
        }
        case GET_PRESENTATIONS_DATA_SUCCESS: {
            newState = {
                ...state,
                initialData: {
                    ...state.initialData,
                    presentationsData: {
                        ...state.initialData.presentationsData,
                        data: action.payload,
                        error: false,
                        success: true,
                        loading: false
                    }
                }
            };
            break;
        }
        case ON_PAGE_CHANGE_CLICK: {
            newState = { ...state };
            newState.initialData.docPageCount = action.payload.numPages;
            if (action.payload.value === 'next') {
                newState.initialData.pageNumber =
                    newState.initialData.pageNumber + 1;
            } else {
                newState.initialData.pageNumber =
                    newState.initialData.pageNumber - 1;
            }
            newState.initialData.pageNoChange = true;
            break;
        }
        // case CURRENT_TIMESTAMP: {
        //     newState = { ...state };
        //     newState.initialData.pageNoChange = false;
        //     let newParm = {
        //         "time": Math.floor(action.payload.tStamp.current.currentTime),
        //         "page": newState.initialData.pageNumber
        //     }
        //     newState.initialData.timestamp.push(newParm)
        //     break;
        // }
        case ON_RESET_RECORDED_TIMESTAMP: {
            newState = { ...state };
            newState.initialData.pageNoChange = false;
            newState.initialData.timestamp = [];
            break;
        }
        case ON_PAGE_CHANGE_TIMESTAMP_RECORD: {
            newState = { ...state };
            newState.initialData.pageNoChange = false;
            let newParm = {
                time: Math.floor(action.payload.timestamp),
                page: action.payload.pageNumber
            };
            newState.initialData.timestamp.push(newParm);
            break;
        }
        case PAGE_UPDATE: {
            newState = { ...state };
            newState.stopRerender = false;
            newState.initialData.pageNumber = action.payload.value;

            break;
        }
        case RESET_PDF_PAGE_NUMBER: {
            newState = {
                ...state,
                initialData: {
                    ...state.initialData,
                    pageNumber: 1
                }
            };
            break;
        }
        case NO_CHANGE: {
            newState = { ...state };
            newState.initialData.noChng = newState.initialData.noChng + 1;
            break;
        }
        case RESET_RECORDING_STATE: {
            newState = { ...state };
            newState.initialData.pdfData = '';
            newState.initialData.pdfFileName = '';
            newState.initialData.videoPreview = false;
            newState.initialData.timestamp = [];
            break;
        }
        case SET_VIDEO_PREVIEW_TO_FALSE: {
            newState = { ...state };
            newState.initialData.videoPreview = false;
            newState.initialData.pageNumber = 1;
            break;
        }
        case ON_SET_RECORDED_VIDEO_DURATION: {
            newState = {
                ...state,
                initialData: {
                    ...state.initialData,
                    recordedVideoDuration: action.payload
                }
            };
            break;
        }
        case ON_RESET_RECORDED_VIDEO_DURATION: {
            newState = {
                ...state,
                initialData: {
                    ...state.initialData,
                    recordedVideoDuration: 0,
                    recordedVideo: {},
                    recordedVideoS3: {},
                    recordedVideoUploadProgress: 10
                }
            };
            break;
        }
        case ON_SAVE_RECORDED_VIDEO: {
            newState = { ...state };
            if (!action.payload.reload) {
                newState.initialData.videoPreview = true;
                newState.initialData.showVideo = false;
                // newState.initialData.pageNumber =
                //     action.payload.initRecordingPage;
                newState.initialData.recordedVideo = action.payload.value;
            }

            break;
        }
        case S3_VIDEO_DATA: {
            newState = { ...state };
            newState.initialData.recordedVideoS3 = action.payload.value;
            break;
        }
        case S3_VIDEO_UPLOAD_PROGRESS: {
            newState = { ...state };
            if (
                action.payload.value >
                newState.initialData.recordedVideoUploadProgress
            ) {
                newState.initialData.recordedVideoUploadProgress =
                    action.payload.value;
            }
            break;
        }
        case ADD_NEW_KEYWORD: {
            newState = { ...state };
            var newkeyword = { word: action.payload.value };
            newState.initialData.keywordsValue = [
                ...newState.initialData.keywordsValue,
                newkeyword
            ];
            break;
        }
        case ON_REMOVE_KEYWORD: {
            newState = { ...state };
            newState.initialData.keywordsValue.splice(action.payload.value, 1);
            break;
        }
        case ADD_NEW_CATEGORY: {
            newState = { ...state };
            newState.initialData.category = action.payload.value;
            break;
        }
        case ON_LEARNING_OBJECTIVES_CHANGE: {
            newState = {
                ...state,
                initialData: {
                    ...state.initialData,
                    learningObjectives: action.payload.value
                }
            };
            break;
        }
        case ON_REFERENCES_CHANGE: {
            newState = {
                ...state,
                initialData: {
                    ...state.initialData,
                    references: action.payload.value
                }
            };
            break;
        }
        case ON_CONFLICTS_CHANGE: {
            newState = {
                ...state,
                initialData: {
                    ...state.initialData,
                    conflicts: action.payload.value
                }
            };
            break;
        }
        case ON_INPUT_HANDLE_CHANGE: {
            const value = action.payload.value;
            newState = { ...state };
            if (action.payload.id === 'description') {
                newState.initialData.description = value;
            } else if (action.payload.id === 'purpose') {
                newState.initialData.purpose = value;
            } else if (action.payload.id === 'email') {
                newState.initialData.emailAddress = value;
            } else if (action.payload.id === 'first_name') {
                newState.initialData.userProfile.data.first_name = value;
            } else if (action.payload.id === 'last_name') {
                newState.initialData.userProfile.data.last_name = value;
            } else if (action.payload.id === 'occupation') {
                newState.initialData.userProfile.data.occupation = value;
            } else if (action.payload.id === 'linkedin_url') {
                newState.initialData.userProfile.data.linkedin_url = value;
            } else if (action.payload.id === 'twitter_url') {
                newState.initialData.userProfile.data.twitter_url = value;
            } else if (action.payload.id === 'fb_url') {
                newState.initialData.userProfile.data.fb_url = value;
            } else if (action.payload.id === 'website_url') {
                newState.initialData.userProfile.data.website_url = value;
            } else if (action.payload.id === 'about_me') {
                newState.initialData.userProfile.data.about_me = value;
            } else if (action.payload.id === 'title') {
                newState.initialData.userProfile.data.title = value;
            } else if (action.payload.id === 'credentials') {
                newState.initialData.userProfile.data.credentials = value;
            }

            break;
        }
        case ON_CONDITION_VARIABLE: {
            newState = { ...state };
            if (action.payload.id === 'fileLoader') {
                newState.initialData.fileLoader = action.payload.value;
                if (!action.payload.value) {
                    newState.initialData.pdfFileName = '';
                    newState.initialData.pdfData = '';
                }
            }
            break;
        }
        case ON_TITLE_CHANGE: {
            newState = { ...state };
            newState.initialData.videoTitle = action.payload.value.trimStart();
            break;
        }
        case ON_PRESENTATION_LENGTH_CHANGE: {
            newState = { ...state };
            newState.initialData.presentationLength = action.payload.value;
            break;
        }
        case ON_CATEGORY_PAGE_CHANGE: {
            newState = { ...state };

            if (action.payload.value === 'ShowPopup') {
                newState.initialData.profileView =
                    !newState.initialData.profileView;
            } else {
                if (action.payload.value.length > 1) {
                    newState.initialData.dashboardPage = true;
                } else if (action.payload.value.length === 1) {
                    newState.initialData.dashboardPage = false;
                }
            }
            break;
        }
        case SET_PROFILE_PICTURE: {
            newState = { ...state };
            newState.initialData.userProfile.data.profileImage =
                action.payload.value;
            break;
        }
        case ON_STORE_PROFILE_PICTURE_START: {
            newState = {
                ...state,
                initialData: {
                    ...state.initialData,
                    userProfileImage: {
                        loading: true,
                        success: false,
                        error: null,
                        data: null
                    }
                }
            };

            break;
        }
        case ON_STORE_PROFILE_PICTURE_SUCCESS: {
            newState = {
                ...state,
                initialData: {
                    ...state.initialData,
                    userProfileImage: {
                        loading: false,
                        success: true,
                        error: null,
                        data: action.payload
                    }
                }
            };
            break;
        }
        case ON_STORE_PROFILE_PICTURE_FAIL: {
            newState = {
                ...state,
                initialData: {
                    ...state.initialData,
                    userProfileImage: {
                        loading: false,
                        success: false,
                        error: action.payload,
                        data: null
                    }
                }
            };
            break;
        }
        case ON_STORE_PROFILE_PICTURE_RESET: {
            newState = {
                ...state,
                initialData: {
                    ...state.initialData,
                    userProfileImage: {
                        loading: false,
                        success: false,
                        error: null,
                        data: null
                    }
                }
            };
            break;
        }
        case ON_STORE_PROFILE_START: {
            newState = {
                ...state,
                initialData: {
                    ...state.initialData,
                    updateUserProfile: {
                        loading: true,
                        success: false,
                        error: null,
                        data: null
                    }
                }
            };

            break;
        }
        case ON_STORE_PROFILE_SUCCESS: {
            newState = {
                ...state,
                initialData: {
                    ...state.initialData,
                    updateUserProfile: {
                        loading: false,
                        success: true,
                        error: null,
                        data: action.payload
                    }
                }
            };
            break;
        }
        case ON_STORE_PROFILE_FAIL: {
            newState = {
                ...state,
                initialData: {
                    ...state.initialData,
                    updateUserProfile: {
                        loading: false,
                        success: false,
                        error: action.payload,
                        data: null
                    }
                }
            };
            break;
        }
        case ON_STORE_PROFILE_RESET: {
            newState = {
                ...state,
                initialData: {
                    ...state.initialData,
                    updateUserProfile: {
                        loading: false,
                        success: false,
                        error: null,
                        data: null
                    }
                }
            };
            break;
        }
        case PRESENTATION_DATA: {
            newState = { ...state };
            newState.initialData.presentationData = action.payload.value;
            break;
        }

        case ON_PRESENTATION_INFO_SUBMIT_START: {
            newState = {
                ...state,
                initialData: {
                    ...state.initialData,
                    submitPresentationData: {
                        loading: true,
                        success: false,
                        error: false,
                        data: null
                    }
                }
            };
            break;
        }

        case ON_PRESENTATION_INFO_SUBMIT_SUCCESS: {
            newState = {
                ...state,
                initialData: {
                    ...state.initialData,
                    submitPresentationData: {
                        loading: false,
                        success: true,
                        error: false,
                        data: action.payload
                    }
                }
            };
            break;
        }

        case ON_PRESENTATION_INFO_SUBMIT_FAIL: {
            newState = {
                ...state,
                initialData: {
                    ...state.initialData,
                    submitPresentationData: {
                        loading: false,
                        success: false,
                        error: action.payload,
                        data: null
                    }
                }
            };
            break;
        }

        case ON_PRESENTATION_RESET: {
            newState = {
                ...state,
                initialData: {
                    ...state.initialData,
                    pdfData: '',
                    category: [],
                    keywordsValue: [],
                    description: '',
                    videoTitle: '',
                    purpose: '',
                    learningObjectives: [],
                    references: [],
                    conflicts: [],
                    videoForPresentationData: null,
                    videoForPresentationName: null,
                    presentationFileUploading: {
                        loading: false,
                        success: false,
                        error: null,
                        data: {
                            progress: 0,
                            fileName: null
                        }
                    },
                    presentationFileUploadingStatus: {
                        loading: false,
                        success: false,
                        error: false
                    },
                    presentationFiles: [],
                    presentationFilesFailedToUpload: []
                }
            };
            break;
        }

        case GET_PRESENTATION_DATA_START: {
            newState = {
                ...state,
                initialData: {
                    ...state.initialData,
                    getPresentation: {
                        error: null,
                        success: false,
                        loading: true,
                        data: null
                    }
                }
            };
            break;
        }
        case GET_PRESENTATION_DATA_SUCCESS: {
            newState = {
                ...state,
                initialData: {
                    ...state.initialData,
                    getPresentation: {
                        error: null,
                        success: true,
                        loading: false,
                        data: action.payload.data
                    },
                    pdfData: {
                        url: action.payload.data.pdf_url,
                        presentationId: action.payload.presentationId
                    }
                }
            };
            break;
        }
        case GET_PRESENTATION_DATA_FAIL: {
            newState = {
                ...state,
                initialData: {
                    ...state.initialData,
                    getPresentation: {
                        error: action.payload,
                        success: false,
                        loading: false,
                        data: null
                    }
                }
            };
            break;
        }

        case ON_PRESENTATION_INFO_RESET: {
            newState = {
                ...state,
                initialData: {
                    ...state.initialData,
                    description: '',
                    videoTitle: ''
                }
            };
            break;
        }

        case UPDATE_PRESENTATION_INFO_START: {
            newState = {
                ...state,
                initialData: {
                    ...state.initialData,
                    updatePresentationInfo: {
                        error: null,
                        success: false,
                        loading: true
                    }
                }
            };
            break;
        }

        case UPDATE_PRESENTATION_INFO_SUCCESS: {
            newState = {
                ...state,
                initialData: {
                    ...state.initialData,
                    updatePresentationInfo: {
                        error: null,
                        success: true,
                        loading: false
                    }
                }
            };
            break;
        }

        case UPDATE_PRESENTATION_INFO_RESET: {
            newState = {
                ...state,
                initialData: {
                    ...state.initialData,
                    updatePresentationInfo: {
                        error: null,
                        success: false,
                        loading: false
                    }
                }
            };
            break;
        }

        case UPDATE_PRESENTATION_INFO_FAIL: {
            newState = {
                ...state,
                initialData: {
                    ...state.initialData,
                    updatePresentationInfo: {
                        error: action.payload,
                        success: false,
                        loading: false
                    }
                }
            };
            break;
        }

        case ON_SAVE_RECORDED_VIDEO_START: {
            newState = {
                ...state,
                initialData: {
                    ...state.initialData,
                    recordedVideoUpload: {
                        error: null,
                        success: false,
                        loading: true,
                        data: null
                    }
                }
            };
            break;
        }

        case ON_SAVE_RECORDED_VIDEO_FAIL: {
            newState = {
                ...state,
                initialData: {
                    ...state.initialData,
                    recordedVideoUpload: {
                        error: action.payload,
                        success: false,
                        loading: false,
                        data: null
                    }
                }
            };
            break;
        }

        case ON_SAVE_RECORDED_VIDEO_SUCCESS: {
            newState = {
                ...state,
                initialData: {
                    ...state.initialData,
                    recordedVideoUpload: {
                        error: null,
                        success: true,
                        loading: false,
                        data: action.payload
                    }
                }
            };
            break;
        }

        case REMOVE_PRESENTATION_START: {
            newState = {
                ...state,
                initialData: {
                    ...state.initialData,
                    removePresentation: {
                        error: null,
                        success: false,
                        loading: true,
                        data: null
                    }
                }
            };
            break;
        }

        case REMOVE_PRESENTATION_SUCCESS: {
            newState = {
                ...state,
                initialData: {
                    ...state.initialData,
                    removePresentation: {
                        error: null,
                        success: true,
                        loading: false,
                        data: action.payload
                    },
                    getPresentation: {
                        error: null,
                        success: false,
                        loading: false,
                        data: null
                    },
                    videoTitle: '',
                    description: ''
                }
            };
            break;
        }

        case REMOVE_PRESENTATION_FAIL: {
            newState = {
                ...state,
                initialData: {
                    ...state.initialData,
                    removePresentation: {
                        error: action.payload,
                        success: false,
                        loading: false,
                        data: null
                    }
                }
            };
            break;
        }

        case REMOVE_PRESENTATION_RESET: {
            newState = {
                ...state,
                initialData: {
                    ...state.initialData,
                    removePresentation: {
                        error: null,
                        success: false,
                        loading: false,
                        data: null
                    }
                }
            };
            break;
        }

        case ON_PRESENTATION_FILE_SELECTOR_RESET:
            newState = {
                ...state,
                initialData: {
                    ...state.initialData,
                    presentationFileSelector: {
                        errors: {
                            maxFileSize: null,
                            minFileSize: null,
                            wrongFileType: null
                        }
                    }
                }
            };
            break;

        case ON_PRESENTATION_FILE_SELECTOR_ERROR:
            newState = {
                ...state,
                initialData: {
                    ...state.initialData,
                    presentationFileSelector: {
                        ...state.initialData.presentationFileSelector,
                        errors: {
                            ...state.initialData.presentationFileSelector
                                .errors,
                            [action.payload.type]: action.payload.value
                        }
                    }
                }
            };
            break;

        default:
            break;
    }
    return newState;
}
