import React, { useRef, useState } from 'react';
import { RiAccountCircleFill } from 'react-icons/ri';
import styles from './ProfilePicture.module.css';
const profileImageURL = (userId) =>
    `${process.env.REACT_APP_PROFILE_IMAGES_URL}/users/${userId}/picture/profile.jpg`;

const ProfilePicture = (props) => {
    const [showDefaultProfileImage, setShowDefaultProfileImage] =
        useState(null);
    const profileImageRef = useRef();

    const getUserProfileImage = (userId) => {
        if (userId !== null) {
            return profileImageURL(userId);
        }
        return '';
    };

    const profileImageClasses = [styles.ProfileImage];

    if (props.className) {
        profileImageClasses.push(props.className);
    }

    const { userId } = props;

    return (
        <>
            {/* eslint-disable jsx-a11y/alt-text */}
            {showDefaultProfileImage || !userId ? (
                <RiAccountCircleFill
                    className={profileImageClasses.join(' ')}
                    size={'1.8em'}
                    color="#F9B041"
                />
            ) : (
                <img
                    className={profileImageClasses.join(' ')}
                    ref={profileImageRef}
                    src={getUserProfileImage(userId)}
                    onError={() => {
                        setShowDefaultProfileImage(true);
                    }}
                />
            )}
        </>
    );
};

export default ProfilePicture;
