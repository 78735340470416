import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { FileDrop } from 'react-file-drop';
import {
    onUploadPresentationFile,
    onUploadAllPresentationFilesResetAction,
    onUploadPresentationFileResetAction,
    onDiscardUploadedPresentationFilesAction,
    onRemoveFileFromPresentationFilesAction,
    resetRecordingState,
    setVideoPreviewToFalse,
    onPresentationReset,
    getUserProfile,
    onPresentationFileSelectorResetAction,
    uploadVideoEmbed
} from 'redux/action/Index';
import FileChooser from './FileChooser/FileChooser';
import EmbedVideo from './EmbedVideo/EmbedVideo';
import FilesUploadStatusWrapper from './FilesUploadStatusWrapper/FilesUploadStatusWrapper';
import Button from 'components/ButtonComp/ButtonComp';
import Loader from 'components/LoaderComp/LoaderComp';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import _ from 'lodash';
import styles from './PresentationUpload.module.css';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    }
}));

const acceptedTypes = [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/pdf',
    'application/mspowerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/x-iwork-keynote-sffkey',
    'video/mp4',
    'video/quicktime',
    'video/webm'
];

const typeMap = {
    doc: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    pdf: 'application/pdf',
    ppt: 'application/mspowerpoint',
    pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    key: 'application/x-iwork-keynote-sffkey',
    mp4: 'video/mp4',
    mov: 'video/quicktime',
    webm: 'video/webm'
};

const videoTypes = ['mp4', 'mov', 'webm'];

const PresentationUpload = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const presentationFileUploadingState = useSelector(
        (state) => state.reducer.initialData.presentationFileUploading
    );
    const presentationFilesState = useSelector(
        (state) => state.reducer.initialData.presentationFiles
    );
    const presentationFileUploadingStatusState = useSelector(
        (state) => state.reducer.initialData.presentationFileUploadingStatus
    );
    const submitPresentationDataState = useSelector(
        (state) => state.reducer.initialData.submitPresentationData
    );
    const presentationFilesFailedToUploadState = useSelector(
        (state) => state.reducer.initialData.presentationFilesFailedToUpload
    );
    const [presentationFilesToUpload, setPresentationFilesToUpload] = useState(
        []
    );
    const [maxFilesUploadLimitReached, setMaxFilesUploadLimitReached] =
        useState(false);

    useEffect(() => {
        dispatch(getUserProfile());
        dispatch(onPresentationReset());
        dispatch(resetRecordingState());
        dispatch(setVideoPreviewToFalse());
        dispatch(onPresentationFileSelectorResetAction());
        dispatch(onUploadPresentationFileResetAction());
        dispatch(onUploadAllPresentationFilesResetAction());
    }, []);

    const onDropFiles = async (files, event) => {
        presentationFilesSelectionHandler(files);
    };

    const onFilesBrowserSelection = (files) => {
        presentationFilesSelectionHandler(files);
    };

    const getFileExtension = (baseName) => {
        return baseName.toLowerCase().split('.').pop();
    };

    const checkNameOfTheFile = (newFileName) => {
        const counter = presentationFilesToUpload.filter(
            (f) => f.fileName === newFileName
        ).length;
        if (counter === 0) {
            return newFileName;
        }
        if (counter === 1) {
            const newName = `${newFileName.split('.')[0]}_${counter}.${
                newFileName.split('.')[1]
            }`;
            return checkNameOfTheFile(newName);
        }
    };

    const uploadVideoEmbedHandler = (embedLink) => {
        if (presentationFilesToUpload.length >= 1) {
            setMaxFilesUploadLimitReached(true);
            return;
        } else {
            setMaxFilesUploadLimitReached(false);
        }

        const localFilesList = [];
        const embedObj = {
            file: null,
            fileName: embedLink,
            fileType: '',
            fileSize: '',
            isVideoType: true,
            error: {
                maxVideoFileSize: false,
                maxFileSize: false,
                unsupportedFileType: false,
                failedToUploadFile: false
            },
            isFileReadyToUpload: false,
            progress: 0
        };
        localFilesList.push(embedObj);
        setPresentationFilesToUpload((state) => [...state, ...localFilesList]);
        dispatch(uploadVideoEmbed(embedLink));
    };

    const presentationFilesSelectionHandler = (files) => {
        if (presentationFileUploadingStatusState.loading) {
            return;
        }

        if (
            presentationFilesToUpload.length >= 1 ||
            files.length + presentationFilesToUpload.length > 1
        ) {
            setMaxFilesUploadLimitReached(true);
            return;
        } else {
            setMaxFilesUploadLimitReached(false);
        }

        const localFilesList = [];

        for (let fileIndex = 0; fileIndex < files.length; fileIndex++) {
            const file = files[fileIndex];
            const fileDetails = {
                file: null,
                fileName: '',
                fileType: '',
                fileSize: '',
                isVideoType: false,
                error: {
                    maxVideoFileSize: false,
                    maxFileSize: false,
                    unsupportedFileType: false,
                    failedToUploadFile: false
                },
                isFileReadyToUpload: false,
                progress: 0
            };

            const fileName = checkNameOfTheFile(file.name);
            let fileType = file.type;
            const fileSize = file.size;
            const fileExtension = getFileExtension(fileName);

            if (acceptedTypes.indexOf(fileType) === -1) {
                fileType = typeMap[fileExtension];
            }

            fileDetails.file = file;
            fileDetails.fileName = fileName;
            fileDetails.fileType = fileType;
            fileDetails.fileSize = fileSize;

            if (videoTypes.indexOf(fileExtension) !== -1) {
                fileDetails.isVideoType = true;
            }

            if (!fileDetails.isVideoType && fileSize > 104857600) {
                fileDetails.error.maxFileSize = true;
            } else if (fileDetails.isVideoType && fileSize > 5368709120) {
                fileDetails.error.maxVideoFileSize = true;
            } else if (acceptedTypes.indexOf(fileType) !== -1) {
                fileDetails.isFileReadyToUpload = true;
            } else {
                fileDetails.error.unsupportedFileType = true;
            }

            localFilesList.push(fileDetails);
        }

        console.log(localFilesList);

        setPresentationFilesToUpload((state) => [...state, ...localFilesList]);

        const filesToUploadOnServer = localFilesList.filter(
            (file) => file.isFileReadyToUpload
        );

        dispatch(onUploadPresentationFile(filesToUploadOnServer));
    };

    const onDiscardUploadedPresentationFilesHandler = () => {
        dispatch(onUploadPresentationFileResetAction());
        dispatch(onDiscardUploadedPresentationFilesAction());
        setPresentationFilesToUpload([]);
        setMaxFilesUploadLimitReached(false);
    };

    const onNextPageHandler = () => {
        history.push('/media-option');
    };

    const onDiscardErrorFileHandler = (fileName) => {
        let updatedPresentationFilesToUpload = _.cloneDeep(
            presentationFilesToUpload
        );
        updatedPresentationFilesToUpload =
            updatedPresentationFilesToUpload.filter(
                (file) => file.fileName !== fileName
            );
        setPresentationFilesToUpload(updatedPresentationFilesToUpload);
        if (updatedPresentationFilesToUpload.length < 10) {
            setMaxFilesUploadLimitReached(false);
        }
    };

    const onRemoveUploadedFileHandler = (fileName) => {
        let updatedPresentationFilesToUpload = _.cloneDeep(
            presentationFilesToUpload
        );
        updatedPresentationFilesToUpload =
            updatedPresentationFilesToUpload.filter(
                (file) => file.fileName !== fileName
            );
        setPresentationFilesToUpload(updatedPresentationFilesToUpload);
        dispatch(onRemoveFileFromPresentationFilesAction(fileName));
        if (updatedPresentationFilesToUpload.length < 10) {
            setMaxFilesUploadLimitReached(false);
        }
    };

    let containerUI = (
        <Grid container alignItems="center" justify="center">
            <Grid item xs={10}>
                <Grid container>
                    <Grid item xs={12}>
                        <h2 className={styles.MainHeading}>File upload</h2>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container className={styles.Container}>
                            <Grid item xs={12}>
                                <div className={styles.SubHeadingContainer}>
                                    <h3 className={styles.SubHeading}>
                                        Upload existing file here
                                    </h3>
                                </div>
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <div className={styles.FileUploadContainer}>
                                    <FileDrop
                                        className={`file-drop ${styles.FileDrop}`}
                                        onDrop={(files, event) =>
                                            onDropFiles(files, event)
                                        }>
                                        <div
                                            className={
                                                styles.FileUploadImageContainer
                                            }>
                                            <img
                                                src={require('../../assets/images/additional-reference-files-upload/file-upload-icon.png')}
                                                alt=""
                                                className={
                                                    styles.FileUploadImage
                                                }
                                            />
                                        </div>

                                        {
                                            <>
                                                <p
                                                    className={
                                                        styles.DragAndDropMessage
                                                    }>
                                                    Drag & Drop File
                                                </p>
                                                <p className={styles.Or}>or</p>
                                                <FileChooser
                                                    onFilesBrowserSelection={
                                                        onFilesBrowserSelection
                                                    }
                                                    presentationFileUploadingStatusState={
                                                        presentationFileUploadingStatusState
                                                    }
                                                />
                                                <p className={styles.Or}>or</p>
                                                <EmbedVideo
                                                    uploadVideoEmbedHandler={
                                                        uploadVideoEmbedHandler
                                                    }
                                                />
                                                <p
                                                    className={
                                                        styles.SupportedFileTypeMessage
                                                    }>
                                                    Supported files: PDF,
                                                    PowerPoint, Word, Keynote
                                                </p>

                                                <p
                                                    className={
                                                        styles.SupportedFileTypeMessage
                                                    }>
                                                    Supported videos: MP4, MOV,
                                                    WebM
                                                </p>

                                                {maxFilesUploadLimitReached ? (
                                                    <span
                                                        className={
                                                            styles.MaxFilesUploadLimitReachedError
                                                        }>
                                                        Limit Reached: You
                                                        cannot upload more than
                                                        1 module file
                                                    </span>
                                                ) : null}
                                            </>
                                        }
                                    </FileDrop>
                                </div>
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <FilesUploadStatusWrapper
                                    presentationFilesToUpload={
                                        presentationFilesToUpload
                                    }
                                    presentationFileUploadingState={
                                        presentationFileUploadingState
                                    }
                                    presentationFilesState={
                                        presentationFilesState
                                    }
                                    presentationFileUploadingStatusState={
                                        presentationFileUploadingStatusState
                                    }
                                    onDiscardUploadedPresentationFilesHandler={
                                        onDiscardUploadedPresentationFilesHandler
                                    }
                                    onDiscardErrorFileHandler={
                                        onDiscardErrorFileHandler
                                    }
                                    onRemoveUploadedFileHandler={
                                        onRemoveUploadedFileHandler
                                    }
                                    presentationFilesFailedToUploadState={
                                        presentationFilesFailedToUploadState
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={10}>
                <div className={styles.FooterContainer}>
                    <Button
                        id={'PresentationUploadNextButtonAction'}
                        name={'Next'}
                        handleClick={() => onNextPageHandler()}
                        btnDisable={
                            presentationFilesState.length < 1 ||
                            !presentationFileUploadingStatusState.success
                        }
                    />
                </div>
            </Grid>
        </Grid>
    );

    if (submitPresentationDataState.loading) {
        containerUI = <Loader />;
    }

    return <div className={classes.root}>{containerUI}</div>;
};

export default PresentationUpload;
