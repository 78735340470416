export class Presentation {
    constructor(data) {
        this._pdfData = {
            url: data.pdf_url
        };
        this._recordedVideo = {
            url: data.video_url
        };
        this._timestamps = data.timestamps;
        this._isVideoOnly = data.videoOnly;
    }

    get pdfData() {
        return this._pdfData;
    }

    get recordedVideo() {
        return this._recordedVideo;
    }

    get timestamps() {
        return this._timestamps;
    }

    get isVideoOnly() {
        return this._isVideoOnly;
    }
}
