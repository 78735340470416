import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import styles from './FileUploadStatus.module.css';

const FileUploadStatus = ({
    fileName,
    progress,
    error,
    presentationFileUploadingStatusState,
    onDiscardErrorFileHandler,
    onRemoveUploadedFileHandler
}) => {
    let discardFileUI = null;

    if (!presentationFileUploadingStatusState.loading) {
        discardFileUI = (
            <IconButton
                aria-label="delete"
                onClick={() => onRemoveUploadedFileHandler(fileName)}>
                <CloseIcon />
            </IconButton>
        );
    }

    if (
        (error.maxFileSize ||
            error.unsupportedFileType ||
            error.failedToUploadFile) &&
        !presentationFileUploadingStatusState.loading
    ) {
        discardFileUI = (
            <IconButton
                aria-label="delete"
                onClick={() => onDiscardErrorFileHandler(fileName)}>
                <CloseIcon />
            </IconButton>
        );
    }

    return (
        <div className={styles.Container}>
            <img
                src={require('../../../../assets/images/additional-reference-files-upload/file-icon.png')}
                alt=""
                className={styles.FileUploadImage}
            />
            <div className={styles.FileDetailContainer}>
                <span className={styles.FileName}>{fileName}</span>
                <LinearProgress
                    variant="determinate"
                    value={progress}
                    className={styles.LinearProgress}
                />

                <div className={styles.ErrorContainer}>
                    {error.maxVideoFileSize ? (
                        <span className={styles.ErrorMessage}>
                            Video File size is greater than 5GB.
                        </span>
                    ) : null}
                    {error.maxFileSize ? (
                        <span className={styles.ErrorMessage}>
                            File size is greater than 100MB.
                        </span>
                    ) : null}
                    {error.unsupportedFileType ? (
                        <span className={styles.ErrorMessage}>
                            File type not supported.
                        </span>
                    ) : null}{' '}
                    {error.failedToUploadFile ? (
                        <span className={styles.ErrorMessage}>
                            Failed to upload the file. Discard it and retry.
                        </span>
                    ) : null}
                </div>
            </div>

            {discardFileUI}
        </div>
    );
};

export default FileUploadStatus;
