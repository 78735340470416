export const getDurationFromSeconds = (secondsArg) => {
    let hours = Math.floor(secondsArg / 3600);
    let minutes = Math.floor((secondsArg - hours * 3600) / 60);
    let seconds = secondsArg - hours * 3600 - minutes * 60;

    if (hours < 10) {
        hours = '0' + hours;
    }
    if (minutes < 10) {
        minutes = '0' + minutes;
    }
    if (seconds < 10) {
        seconds = '0' + seconds;
    }
    return hours + ':' + minutes + ':' + seconds;
};

export const getShortDurationFromSeconds = (secondsArg) => {
    const duration = getDurationFromSeconds(secondsArg);
    const durationTimes = duration.split(':');
    return `${durationTimes[0]}:${durationTimes[1]}`;
};
