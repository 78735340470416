const initText = {
    presenterHomePage: {
        text1: 'Opportunity meets impact ',
        text2: 'We believe that every individual has the ability to change the world. One just needs the opportunity to showcase their talent. Join the community by uploading your module.',
        btnText: "Let's get started",
        text3: 'If it matters to you, it matters to others.<br/> Join the community by uploading your module.',
        text4: 'Here are our most popular resources.',
        text5: 'Tips and Tricks',
        text6: 'Figure out the best physical backgrounds, and<br/> make your modules shine even more! From<br/> small tips about backgrounds, lightening, and<br/> keep clasroom engaged with video-modules.',
        text7: 'Beaker Health Community',
        text8: 'Access to the community: Join discussions, share<br/> your own work, and connect with other learners<br/> in your field.',
        text9: 'Help and Support',
        text10: 'No problems, no progress. Nobody is perfect, but having<br/> the attitude to improve is the most important. Starting<br/> here, you can find the most common issues about trou- <br/>bleshooting to get the most out of Beaker Health.',
        dataChartTitle: 'No. of Modules',
        dataChart2Title: 'Hours Watched Globally'
    },
    mediaoption: {
        text1: 'How about a working title?',
        text2: "It's ok if you can't think of a good title now you can change it later.",
        text3: 'e.g Replacing Clinical Hours with simulation Education',
        text4: "First, let's find out what type of visual module you're making. ",
        text5: 'Create a Visual pressentation',
        text6: 'Create rich learning experiences with the help of video module, audio, pdf etc.',
        text7: 'Click here if you prefer an audio only modules.',
        text8: 'How long is your module?'
    },
    categoryPricePage: {
        categoryHeading: 'What category best fits the visual module?',
        categoryDescription:
            'You may add more than one category. Select other if nothing fits.',
        categoryTip: 'Tip: Add minimum one category',
        keywordsHeading: 'Now include some keywords...',
        keywordsDescription:
            'These words will help others when searching for your module',
        keywordsTip: 'Tip: Hit Enter key to add keywords',
        descriptionHeading: 'How about a short description?',
        descriptionDescription: 'This is the module abstract.',
        descriptionTip: 'Tip: Add Some Characters about the visual module',
        referencesHeading: 'Any references?',
        referencesDescription:
            'These are any sources or URLs you used in your module',
        referencesTip: 'Tip: Add some references for the visual module',
        conflictsHeading: 'Do you have any conflicts of interest?',
        conflictsDescription: 'Leave blank if nothing to report',
        conflictsTip: 'Tip: Add some conflicts about the visual module',
        additionalReferenceFilesHeading: 'Upload additional reference files?'
    },
    editPresentationPage: {
        title: 'Modules details'
    }
};

const ApplicationText = (state = initText) => {
    return state;
};

export default ApplicationText;
