import { commonUTCToLocalDateFormat } from 'utils/date';

export default class Presentation {
    constructor(id, title, viewCount, reviewCount, featuredReview, createdDate) {
        this.id = id;
        this.title = title;
        this.viewCount = viewCount ? viewCount : '-';
        this.reviewCount = reviewCount ? reviewCount : '-';
        this.featuredReview = this.getFeaturedReview(featuredReview);
        this.createdDate = createdDate ? commonUTCToLocalDateFormat(createdDate) : '-';
    }

    getFeaturedReview(featuredReview) {
        if (featuredReview && featuredReview.starsAvg) {
            return featuredReview;
        } else if (featuredReview) {
            featuredReview.starsAvg = 0;
            return featuredReview;
        }
        return { starsAvg: 0 };
    }
}