import React, { Component } from 'react';
import { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Page } from 'react-pdf';
import { Link } from 'react-router-dom';
import { requestPresentationData } from 'redux/action/Index';
import { SizeMe } from 'react-sizeme';
import ReactPlayer from 'react-player';
import Grid from '@material-ui/core/Grid';
import styles from './VisualPresentations.module.css';
import Loader from 'components/LoaderComp/LoaderComp';
import { Document } from 'react-pdf/dist/esm/entry.webpack';
import { WithResponsiveQuery } from 'components/Shared/HOC';

class VisualPresentations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            numOfPage: 0,
            page: 1,
            redirect: false,
            allowPlayer: false
        };
    }

    componentDidMount() {
        const presentationId = this.props.match.params.presentationId;
        this.props.requestPresentationData(presentationId);
    }

    onDuration(duration) {}

    ended(ended, props) {}

    onProgress(progress, props) {
        const {
            presentationData: { timestamps }
        } = this.props.state.initialData;

        let runingTime = Math.floor(progress.playedSeconds);
        let mappedTime = timestamps.map((ele) => ele.time);
        let number = mappedTime.reverse().find((e) => e <= runingTime);

        if (number === undefined) {
            this.setState({ page: 1 });
        }

        if (timestamps.some((item) => item.time === runingTime)) {
            let item = timestamps.find((x) => x.time === runingTime);
            this.setState({ page: item.page });
        }
    }

    onDocumentLoadSuccess(value) {
        this.setState({
            numOfPage: value._pdfInfo.numPages,
            allowPlayer: true
        });
    }

    render() {
        const {
            initialData: { getPresentation }
        } = this.props.state;

        const { mediaQuery } = this.props;
        let pdfCanvasHeight = 450;

        if (mediaQuery.isDesktopOrLaptopHD) {
            pdfCanvasHeight = 350;
        } else if (mediaQuery.isDesktopOrLaptopHDPlus) {
            pdfCanvasHeight = 377;
        } else if (mediaQuery.isDesktopOrLaptopHiDpi) {
            pdfCanvasHeight = 393;
        }

        let containerUI = <Loader />;
        let reactVideoPlayerUI = (
            <div className={styles.ReactPlayerLoader}>
                <Loader />
            </div>
        );

        if (this.state.allowPlayer) {
            reactVideoPlayerUI = (
                <ReactPlayer
                    url={getPresentation.data.video_url}
                    className={`react-player ${styles.ReactPlayerUI}`}
                    onDuration={(duration) => this.onDuration(duration)}
                    onProgress={(progress) =>
                        this.onProgress(progress, this.props)
                    }
                    onEnded={(ended) => this.ended(ended, this.props)}
                    width="100%"
                    height="100%"
                    controls
                />
            );
        } else if (getPresentation.data && getPresentation.data.videoOnly) {
            reactVideoPlayerUI = (
                <ReactPlayer
                    url={getPresentation.data.video_url}
                    className={`react-player ${styles.ReactPlayerUI}`}
                    width="100%"
                    height="100%"
                    controls
                />
            );
        }

        if (
            !getPresentation.loading &&
            getPresentation.success &&
            getPresentation.data
        ) {
            console.log(getPresentation.data.video_url);

            containerUI = (
                <Grid container justify={'space-between'}>
                    {!getPresentation.data.videoOnly ? (
                        <Grid
                            item
                            xs={8}
                            className={`${styles.PDFContainerGrid}`}>
                            <div
                                className={`visual-presentations-pdf ${styles.PDFContainer}`}>
                                <div style={{ height: pdfCanvasHeight }}>
                                    <SizeMe>
                                        {({ size }) => {
                                            return (
                                                <Document
                                                    className={styles.Document}
                                                    file={
                                                        getPresentation.data
                                                            .pdf_url
                                                    }
                                                    onLoadSuccess={(value) =>
                                                        this.onDocumentLoadSuccess(
                                                            value
                                                        )
                                                    }>
                                                    <Page
                                                        loading={null}
                                                        className={styles.Page}
                                                        size="A4"
                                                        height={
                                                            size.height
                                                                ? size.height /
                                                                  2
                                                                : pdfCanvasHeight
                                                        }
                                                        scale={1}
                                                        orientation="landscape"
                                                        pageNumber={
                                                            this.state.page
                                                        }
                                                    />
                                                </Document>
                                            );
                                        }}
                                    </SizeMe>
                                </div>
                                <div className={`${styles.PageNumber}`}>
                                    <p className={`page-no-info`}>
                                        {this.state.page}/{this.state.numOfPage}
                                    </p>
                                </div>
                            </div>
                        </Grid>
                    ) : null}
                    <Grid
                        item
                        xs={getPresentation.data.videoOnly ? 12 : 4}
                        className={`${styles.RecordingContainerGrid}`}>
                        {!getPresentation.data.videoOnly ? (
                            <div
                                className={`visual-presentations-video ${styles.RecordingContainer}`}>
                                {reactVideoPlayerUI}
                            </div>
                        ) : (
                            <div className={`${styles.VideoOnlyContainer}`}>
                                {reactVideoPlayerUI}
                            </div>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Link to="/presentations/dashboard/presentations">
                            <button className="mainButton">Exit Preview</button>
                        </Link>
                    </Grid>
                </Grid>
            );
        }

        if (
            !getPresentation.loading &&
            !getPresentation.success &&
            !getPresentation.data
        ) {
            containerUI = <h1>No Module Found</h1>;
        }

        return (
            <Fragment>
                <p className={styles.Title}>Visual Modules</p>
                <Grid container justify={'center'}>
                    <Grid item xs={10} lg={10} xl={10}>
                        {containerUI}
                    </Grid>
                </Grid>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        state: state.reducer,
        ApplicationText: state.ApplicationText
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            requestPresentationData
        },
        dispatch
    );
}

export default WithResponsiveQuery(
    connect(mapStateToProps, mapDispatchToProps)(VisualPresentations)
);
