import React, { Fragment } from 'react';
import Alert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/core/styles';
import CircularProgressWithLabel from 'components/LoaderWithLabel/LoaderWithLabel';
import Loader from 'components/LoaderComp/LoaderComp';
import VideoPreview from 'components/VideoPreview/VideoPreview';
import { USER_MEDIA_PERMISSION_DENIED_ERROR } from 'utils/error-constants';
import { USER_MEDIA_NOT_ALLOWED_PERMISSION_ERROR } from 'utils/error-constants';
import { USER_MEDIA_UNAVAILABLE } from 'utils/error-constants';
import { MEDIA_RECORDER_ERROR } from 'utils/error-constants';
import { MEDIA_PERMISSION_REQUEST_DISMISSED } from 'utils/error-constants';
import { BsArrowCounterclockwise } from 'react-icons/bs';
import styles from './VideoContainer.module.css';
import _ from 'lodash';

const StyledCircularProgressWithLabel = withStyles({
    colorPrimary: {
        backgroundColor: '#fff',
        color: '#01539d'
    }
})(CircularProgressWithLabel);

const MediaDevicesError = ({ userMediaError }) => {
    if (!userMediaError) {
        return null;
    }

    let errorMessage = null;

    switch (userMediaError) {
        case MEDIA_RECORDER_ERROR:
            errorMessage = 'Error with media recorder.';
            break;
        case USER_MEDIA_UNAVAILABLE:
            errorMessage =
                'Streaming unavailable. Please allow media permissions or retry.';
            break;
        case USER_MEDIA_PERMISSION_DENIED_ERROR:
            errorMessage = 'Please allow camera access to record the video.';
            break;
        case USER_MEDIA_NOT_ALLOWED_PERMISSION_ERROR:
            errorMessage = 'Please allow camera access to record the video.';
            break;
        case MEDIA_PERMISSION_REQUEST_DISMISSED:
            errorMessage = 'Please allow camera access to record the video.';
            break;
        default:
            errorMessage = userMediaError;
            break;
    }

    return <Alert severity="error">{errorMessage}</Alert>;
};

const VideoContainer = (props) => {
    const { userMediaError, uploading, initialData } = props;
    const { cameraAccess, streaming, localVideoRef } = props;
    const { duration, onOpenDialogHandler, requestUserMedia } = props;
    const { videoPreviewSrc } = props;
    const { isInternetOnline } = props;
    const { hasVideoUploadError } = props;

    let faileToUploadVideoErrorUI = null;

    if (hasVideoUploadError) {
        faileToUploadVideoErrorUI = (
            <div className={styles.FailedToUploadVideoErrorContainer}>
                <p className={styles.Text}>You are {isInternetOnline ? 'now online' : 'offline'}</p>
                <p className={styles.Text}>Error while uploading the recorded video.</p>
                <p className={styles.Text}>Retry again once you are back online or have stable connection.</p>
            </div>
        )
    }

    let videoContainerUI = (
        <div className={'recordingpage__video__container'}>
            <MediaDevicesError userMediaError={userMediaError} />

            {!initialData.videoPreview || !uploading ? (
                <Fragment>
                    <div className={'recordingpage__video__recorder'}>
                        <video
                            ref={localVideoRef}
                            autoPlay
                            muted></video>
                    </div>

                    <div className="recordingpage__video__duration">
                        <p>
                            <span>
                                {duration}
                                &nbsp;
                            </span>{' '}
                            <span>
                                {' '}
                                /{' '}
                                {'00:' +
                                    initialData.presentationLength +
                                    ':00'}
                            </span>
                            {cameraAccess && streaming ? (
                                <BsArrowCounterclockwise className={styles.ReloadIcon} onClick={onOpenDialogHandler} size={'1.5em'} />
                            ) : null}
                        </p>
                    </div>
                </Fragment>
            ) : (
                <Fragment>
                    <VideoPreview
                        recordedVideo={videoPreviewSrc}
                        timestamp={initialData.timestamp}
                        onPageUpdate={props.onPageUpdate}
                        width={`100%`}
                    />

                    {_.isEmpty(videoPreviewSrc) && !hasVideoUploadError ? (
                        <Fragment>
                            {initialData.recordedVideoUploadProgress ===
                                95 ? (
                                <div className="recordingpage__video__loadingtext">
                                    <Loader />
                                    <p>Finalizing Video Upload</p>
                                </div>
                            ) : (
                                <div className="recordingpage__video__loadingtext">
                                    <StyledCircularProgressWithLabel
                                        value={
                                            initialData.recordedVideoUploadProgress
                                        }
                                        size={75}
                                    />
                                </div>
                            )}
                        </Fragment>
                    ) : null}

                    {faileToUploadVideoErrorUI}
                </Fragment>
            )}
        </div>
    );

    if (!cameraAccess) {
        videoContainerUI = (
            <div className={'recordingpage__recordicon_wrapper'}>
                <img
                    onClick={requestUserMedia}
                    className="recordingpage__recordicon"
                    alt="record"
                    src={require('../../../assets/record-128.png')}
                />
            </div>
        );
    }

    return (videoContainerUI);
}

export default VideoContainer;