import demoData from './demotext';

export default {
    initialData: {
        pageNumber: 1,
        docPageCount: null,
        pdfData: '',
        pdfFileName: '',
        timestamp: [],
        showVideo: false,
        pageNoChange: false,
        videoPreview: false,
        recordedVideo: {},
        recordedVideoS3: {},
        recordedVideoUploadProgress: 10,
        time: 0,
        stopRerender: true,
        noChng: 0,
        category: [],
        keywordsValue: [],
        references: [],
        conflicts: [],
        description: '',
        emailAddress: '',
        fileLoader: false,
        videoTitle: '',
        presentationLength: 30,
        recordedVideoDuration: 0,
        profileView: false,
        presentationsData: '',
        user: null,
        dashboardPage: false,
        purpose: '',
        learningObjectives: [],
        userProfile: {
            loading: false,
            success: false,
            error: null,
            data: {
                title: '',
                credentials: '',
                profileImage: '',
                first_name: '',
                last_name: '',
                occupation: '',
                language: '',
                linkedin_url: '',
                twitter_url: '',
                fb_url: '',
                website_url: '',
                about_me: ''
            }
        },
        videoForPresentationData: null,
        videoForPresentationName: null,
        presentationFileUploading: {
            loading: false,
            success: false,
            error: null,
            data: {
                progress: 0,
                fileName: null
            }
        },
        presentationFileUploadingStatus: {
            loading: false,
            success: false,
            error: false
        },
        presentationFiles: [],
        presentationFilesFailedToUpload: [],
        referenceFileUploading: {
            loading: false,
            success: false,
            error: null,
            data: {
                progress: 0,
                fileName: null
            }
        },
        referenceFilesUploadingStatus: {
            loading: false,
            success: false,
            error: false
        },
        referenceFiles: [],
        referenceFilesFailedToUpload: [],
        updateUserProfile: {
            loading: false,
            success: false,
            error: null,
            data: null
        },
        userProfileImage: {
            loading: false,
            success: false,
            error: null,
            data: null
        },
        presentationData: {
            loading: false,
            success: false,
            error: false,
            data: null
        },
        submitPresentationData: {
            loading: false,
            success: false,
            error: false,
            data: null
        },
        removePresentation: {
            loading: false,
            success: false,
            error: false,
            data: null
        },
        assets: {
            videoDemo: require('../../assets/online_presentation.svg'),
            audioDemo: require('../../assets/audio.png'),
            lightIcon: require('../../assets/light.png'),
            leavesIcon: require('../../assets/leaves.png'),
            ChatIcon: require('../../assets/chat.png'),
            pptIcon: require('../../assets/ppt_icon.png'),
            pdfIcon: require('../../assets/pdf_icon.png'),
            soundIcon: require('../../assets/sound_icon.png'),
            wordIcon: require('../../assets/word_icon.png'),
            keyIcon: require('../../assets/key_icon.png'),
            uploadIcon: require('../../assets/upload.png'),
            videoRecording: require('../../assets/video_recording.png'),
            audioRecording: require('../../assets/audio_recording.png'),
            twitter: require('../../assets/twitter.svg'),
            facebook: require('../../assets/facebook.svg'),
            linkedin: require('../../assets/linkedin.svg')
        },
        HomePageInfoCard: [
            {
                icon: require('../../assets/light.png'),
                title: 'Tips and Tricks',
                desc: 'Figure out the best physical backgrounds, and make your modules shine even more! From small tips about backgrounds, lightening, and keep clasroom engaged with video-modules.'
            },
            {
                icon: require('../../assets/leaves.png'),
                title: 'Beaker Health Community',
                desc: 'Access to the community: Join discussions, share your own work, and connect with other learners in your field.'
            },
            {
                icon: require('../../assets/chat.png'),
                title: 'Help and Support',
                desc: 'No problems, no progress. Nobody is perfect, but having the attitude to improve is the most important. Starting here, you can find the most common issues about troubleshooting to get the most out of Beaker Health.'
            }
        ],
        DataCharts: [
            {
                name: 'Page A',
                uv: 4000,
                pv: 2400,
                amt: 2400
            },
            {
                name: 'Page B',
                uv: 3000,
                pv: 1398,
                amt: 2210
            },
            {
                name: 'Page C',
                uv: 2000,
                pv: 9800,
                amt: 2290
            },
            {
                name: 'Page D',
                uv: 2780,
                pv: 3908,
                amt: 2000
            },
            {
                name: 'Page E',
                uv: 1890,
                pv: 4800,
                amt: 2181
            },
            {
                name: 'Page F',
                uv: 2390,
                pv: 3800,
                amt: 2500
            },
            {
                name: 'Page G',
                uv: 3490,
                pv: 4300,
                amt: 2100
            }
        ],
        StackedBarChartHomePageData: [
            {
                name: 'Page A',
                mv: 3,
                uv: 2,
                pv: 1,
                amt: 2400
            },
            {
                name: 'Page B',
                mv: 6,
                uv: 3,
                pv: 2,
                amt: 2210
            },
            {
                name: 'Page C',
                mv: 5,
                uv: 4,
                pv: 3,
                amt: 2290
            },
            {
                name: 'Page D',
                mv: 9,
                uv: 5,
                pv: 4,
                amt: 2000
            },
            {
                name: 'Page E',
                mv: 10,
                uv: 6,
                pv: 5,
                amt: 2181
            },
            {
                name: 'Page F',
                mv: 3,
                uv: 7,
                pv: 6,
                amt: 2500
            },
            {
                name: 'Page G',
                mv: 12,
                uv: 8,
                pv: 7,
                amt: 2100
            },
            {
                name: 'Page A',
                mv: 13,
                uv: 9,
                pv: 8,
                amt: 2400
            },
            {
                name: 'Page B',
                mv: 14,
                uv: 10,
                pv: 9,
                amt: 2210
            },
            {
                name: 'Page C',
                mv: 15,
                uv: 11,
                pv: 10,
                amt: 2290
            },
            {
                name: 'Page D',
                mv: 17,
                uv: 12,
                pv: 11,
                amt: 2000
            },
            {
                name: 'Page E',
                mv: 19,
                uv: 13,
                pv: 12,
                amt: 2181
            },
            {
                name: 'Page F',
                mv: 16,
                uv: 14,
                pv: 13,
                amt: 2500
            },
            {
                name: 'Page G',
                mv: 11,
                uv: 15,
                pv: 14,
                amt: 2100
            },
            {
                name: 'Page G',
                mv: 9,
                uv: 14,
                pv: 13,
                amt: 2100
            },
            {
                name: 'Page G',
                mv: 8,
                uv: 13,
                pv: 12,
                amt: 2100
            },
            {
                name: 'Page G',
                mv: 13,
                uv: 12,
                pv: 11,
                amt: 2100
            },
            {
                name: 'Page G',
                mv: 11,
                uv: 11,
                pv: 10,
                amt: 2100
            },
            {
                name: 'Page G',
                mv: 8,
                uv: 10,
                pv: 9,
                amt: 2100
            },
            {
                name: 'Page G',
                mv: 9,
                uv: 9,
                pv: 8,
                amt: 2100
            },
            {
                name: 'Page G',
                mv: 5,
                uv: 8,
                pv: 7,
                amt: 2100
            },
            {
                name: 'Page G',
                mv: 3,
                uv: 7,
                pv: 6,
                amt: 2100
            },
            {
                name: 'Page G',
                mv: 1,
                uv: 6,
                pv: 5,
                amt: 2100
            },
            {
                name: 'Page G',
                mv: 1,
                uv: 5,
                pv: 4,
                amt: 2100
            },
            {
                name: 'Page G',
                mv: 3,
                uv: 4,
                pv: 3,
                amt: 2100
            },
            {
                name: 'Page G',
                mv: 3,
                uv: 3,
                pv: 2,
                amt: 2100
            },
            {
                name: 'Page G',
                mv: 3,
                uv: 2,
                pv: 1,
                amt: 2100
            }
        ],
        Category: [
              { title: 'Adult Gerontology' },
              { title: 'Ambulatory Care' },
              { title: 'Anesthesia' },
              { title: 'Audiovisual Technology' },
              { title: 'Cardiac Care' },
              { title: 'Critical Care' },
              { title: 'Community public health' },
              { title: 'Disaster Preparedness' },
              { title: 'Education' },
              { title: 'Family and Individual Lifespan' },
              { title: 'General Practice' },
              { title: 'Home Health' },
              { title: 'Informatics' },
              { title: 'Moulage & Theatrics' },
              { title: 'Nurse Executive' },
              { title: 'Nursing Case Management' },
              { title: 'Nursing Professional Development' },
              { title: 'Operations & Management' },
              { title: 'Pain Management' },
              { title: 'Policy' },
              { title: 'Simulation' },
              { title: 'Other' }
        ],
        mapData: demoData,
        getPresentation: {
            error: null,
            success: false,
            loading: false,
            data: null
        },
        updatePresentationInfo: {
            error: null,
            success: false,
            loading: false
        },
        recordedVideoUpload: {
            loading: false,
            success: false,
            error: false,
            data: null
        },
        uploadFileToS3: {
            loading: false,
            success: false,
            error: false,
            data: null
        },
        presentationFileSelector: {
            errors: {
                maxFileSize: null,
                minFileSize: null,
                unsupportedFileType: null
            }
        }
    }
};
