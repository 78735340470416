import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import './index.css';
import App from './App';
import Modal from "react-modal";
// import * as serviceWorker from './serviceWorker';

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import LogRocket from 'logrocket';

import * as Cookies from 'js-cookie';
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';

Amplify.configure(awsconfig);

const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

let authSettings = null;

if (isLocalhost) {
    // dev code
    authSettings = {
        cookieStorage: {
            domain: 'localhost',
            path: '/',
            expires: 365,
            sameSite: "lax",
            secure: false
        }
    };
} else {
    authSettings = {
        /*region: process.env.REACT_APP_AWS_PROJECT_REGION,
        identityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
        identityPoolRegion: process.env.REACT_APP_AWS_COGNITO_REGION,
        userPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID,
        userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,*/
        // OPTIONAL - Configuration for cookie storage
        // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
        cookieStorage: {
            // REQUIRED - Cookie domain (only required if cookieStorage is provided)
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
            // OPTIONAL - Cookie path
            path: '/',
            // OPTIONAL - Cookie expiration in days
            expires: 365,
            // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
            sameSite: "lax",
            // OPTIONAL - Cookie secure flag
            // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
            secure: true
        }
    };

    //no console log
    console.log = () => { };
}

const apiEndpoints = {
    endpoints: [
        {
            name: process.env.REACT_APP_API_ENDPOINT_NAME,
            endpoint: process.env.REACT_APP_API_ENDPOINT_URL,
            custom_header: async () => {
                return { Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
            }
        }
    ]
}

Amplify.configure({
    Auth: authSettings,
    API: apiEndpoints
});

//setup compression
class MyStorage {
    // set item with the key
    static setItem(key, value) {
        const options = authSettings.cookieStorage;

        const tokenType = key.split('.').pop();
        if (tokenType === 'accessToken' || tokenType === 'userData') {
            localStorage.setItem(key, value);
            return localStorage.getItem(key);
        }

        Cookies.set(key, value, options);
        return Cookies.get(key);
    }
    // get item with the key
    static getItem(key) {
        const tokenType = key.split('.').pop();
        if (tokenType === 'accessToken' || tokenType === 'userData') {
            return localStorage.getItem(key);
        }
        return Cookies.get(key);
    }
    static removeItem(key) {
        const options = authSettings.cookieStorage;
        localStorage.removeItem(key);
        return Cookies.remove(key, options);
    }
    static clear() {
        const cookies = Cookies.get();
        let index;
        for (index = 0; index < cookies.length; ++index) {
            Cookies.remove(cookies[index]);
        }
        localStorage.clear();
        return {};
    }
}

// tell Auth to use your storage object
Auth.configure({
    storage: MyStorage
});

Modal.setAppElement("#root");

//prod logging
Sentry.init({
    dsn: "https://4a4359fb6cd840c6bd332419714f69bc@o589122.ingest.sentry.io/5745714",
    integrations: [new Integrations.BrowserTracing()],
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5,
    release: "beaker-health-presenter-prod@1.0.4"
});

LogRocket.init('nctwo4/beakerhealth');
LogRocket.getSessionURL(sessionURL => {
    Sentry.configureScope(scope => {
      scope.setExtra("sessionURL", sessionURL);
    });
});

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
