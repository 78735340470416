import React from 'react';
import styles from './P.module.css';

const P = (props) => {
    const { inline } = props;

    const themeClasses = [];

    if (inline) {
        themeClasses.push(styles.Inline);
    }

    const classes = `${themeClasses.join(' ')} ${props.className}`;

    return <p {...props} className={classes}>
        {props.children}
    </p>;
}

export default P;