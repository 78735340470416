import React from 'react';
import Chart from "chart.js";
import styles from './LineChart.module.scss';
import { useRef } from 'react';
import { useEffect } from 'react';
import _ from 'lodash';
// Reference https://www.createwithdata.com/react-chartjs-dashboard/

const LineChart = (props) => {

    const chartRef = useRef();
    let myChart = null;

    useEffect(() => {
        if (!_.isEmpty(props.data)) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            myChart = new Chart(chartRef.current, {
                type: 'line',
                options: {
                    ...props.options
                },
                data: {
                    labels: props.data.map(d => d.time),
                    datasets: [{
                        label: props.title,
                        data: props.data.map(d => d.value),
                        backgroundColor: props.color,
                        borderColor: props.color,
                        fill: 'none',
                        pointRadius: 1,
                        borderWidth: 3,
                        lineTension: 0,
                        spanGap: false
                    }]
                }
            });
        }
    }, []);

    useEffect(() => {
        if (_.isEmpty(myChart) && !_.isEmpty(props.data)) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            myChart = new Chart(chartRef.current, {
                type: 'line',
                options: {
                    ...props.options
                },
                data: {
                    labels: props.data.map(d => d.time),
                    datasets: [{
                        label: props.title,
                        data: props.data.map(d => d.value),
                        backgroundColor: props.color,
                        borderColor: props.color,
                        fill: 'none',
                        pointRadius: 1,
                        borderWidth: 3,
                        lineTension: 0,
                        spanGap: false
                    }]
                }
            });
        } else if (myChart) {
            myChart.data.labels = props.data.map(d => d.time);
            myChart.data.datasets[0].data = props.data.map(d => d.value);
            myChart.update();
        }
    }, [props.data]);

    return (
        <div className={styles.graphContainer}>
            <canvas
                id="myChart"
                ref={chartRef}
            />
        </div>
    )
}

export default LineChart;