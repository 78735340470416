export default {
  'demoData' :[
   /*{
     "id":1,
      "category":"Beaker-Health",
      "location":{
         "latitude":"38.8951",
         "longitude":"-95.0364"
      }
   },
   {
      "id":2,
      "category":"Beaker-Health",
      "location":{
         "latitude":"35.8951",
         "longitude":"-95.0364"
      }
   },
   {
      "id":3,
      "category":"Beaker-Health",
      "location":{
         "latitude":"30.8951",
         "longitude":"-95.0364"
      }
   },
   {
      "id":4,
      "category":"Beaker-Health",
      "location":{
         "latitude":"30.9951",
         "longitude":"-95.0364"
      }
   },
   {
      "id":5,
      "category":"Beaker-Health",
      "location":{
         "latitude":"30.9951",
         "longitude":"-95.1364"
      }
   },
   {
      "id":6,
       "category":"Beaker-Health",
       "location":{
          "latitude":"39.8951",
          "longitude":"-95.0364"
       }
    },
    {
      "id":7,
       "category":"Beaker-Health",
       "location":{
          "latitude":"40.8951",
          "longitude":"-95.0364"
       }
    },
    {
      "id":8,
       "category":"Beaker-Health",
       "location":{
          "latitude":"41.8951",
          "longitude":"-95.0364"
       }
    },*/
]
}
