import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ButtonComp from '../ButtonComp/ButtonComp';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import { onInputHandleChange } from '../../redux/action/Index';
import { onFindPresentationOwnerSet } from '../../redux/action/ApiIndex';
import styles from './PresentationPurpose.module.scss';
import Objectives from './Objectives';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

const classes = {
    root: {
        '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid rgba(149, 170, 201, 1)'
        }
    },
    input: {
        color: 'rgba(149, 170, 201, 1)',
        fontSize: '1.25rem',
        fontWeight: 'normal',
        fontStyle: 'normal'
    }
};

class PresentationPurpose extends Component {
    onNextPageHandler = () => {
        const videoForPresentationDataState =
            this.props.videoForPresentationDataState;
        if (
            videoForPresentationDataState &&
            videoForPresentationDataState.videoId &&
            videoForPresentationDataState.presentationId
        ) {
            // this.props.history.push('/presentation-category');
            this.props.history.push('/presentation-replay');
        } else {
            this.props.history.push('/recordingpage');
        }
    };

    render() {
        const { classes } = this.props;
        const {
            state: { initialData }
        } = this.props;

        return (
            <div className={`${styles.stylesPresentationPurpose}`}>
                <div className={`${styles.PresentationPurposeSection}`}>
                    <h1>What is the purpose?</h1>
                    <p>Let us know what this module is telling us</p>
                    <div
                        className={`${styles.PresentationPurposeSectionInput}`}>
                        <Box width="100%">
                            <TextField
                                classes={{
                                    root: classes.root
                                }}
                                id="PresentationPurposeInput"
                                placeholder="Please type here..."
                                multiline
                                InputProps={{
                                    className: classes.input
                                }}
                                style={{ width: '100%' }}
                                value={initialData.purpose || ''}
                                onChange={(event) =>
                                    this.props.onInputHandleChange(
                                        event.target.value,
                                        'purpose'
                                    )
                                }
                                rows={6}
                                variant="outlined"
                            />
                        </Box>
                        <p
                            style={{
                                color: initialData.purpose === '' && 'red'
                            }}>
                            Tip: Write about why you are creating this module
                        </p>
                    </div>
                </div>
                <div className={`${styles.PresentationPurposeSection}`}>
                    <h1>What are the learning objectives?</h1>
                    <p>
                        List the goals of the video module. Try to make these:
                        <br />
                        Measurable, Concise, Specific
                    </p>
                    <div
                        className={`${styles.PresentationPurposeSectionInput}`}>
                        <Objectives {...this.props} />
                    </div>
                </div>
                <div
                    style={{ width: '30%', margin: 'auto', marginTop: '40px' }}>
                    <ButtonComp
                        id={'NextButtonAction'}
                        name={'Next'}
                        handleClick={() =>
                            // this.props.history.push('/recordingpage')
                            this.onNextPageHandler()
                        }
                        btnDisable={
                            initialData.purpose.trim() !== '' &&
                            initialData.learningObjectives.length > 0
                                ? false
                                : true
                        }
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        state: state.reducer,
        videoForPresentationDataState:
            state.reducer.initialData.videoForPresentationData
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            onInputHandleChange,
            onFindPresentationOwnerSet
        },
        dispatch
    );
}

export default withStyles(classes)(
    withRouter(
        connect(mapStateToProps, mapDispatchToProps)(PresentationPurpose)
    )
);
