import React from 'react';
import FileUploadStatus from './FileUploadStatus/FileUploadStatus';
import Button from '@material-ui/core/Button';
import styles from './FilesUploadStatusWrapper.module.css';

const UploadFileCriteriaList = [
    'File size should not be more than 100MB',
    'Maximum 10 reference files can be uploaded'
];

const UploadFileCriteria = () => {
    return (
        <ul className={styles.UploadFileCriteriaContainer}>
            {UploadFileCriteriaList.map((criteria, criteriaIndex) => {
                return (
                    <li
                        key={criteriaIndex}
                        className={styles.UploadFileCriteriaListItem}>
                        <img
                            src={require('../../../assets/images/additional-reference-files-upload/list-item-icon.svg')}
                            alt={'Bullet Icon'}
                        />
                        <span className={styles.UploadCriteriaDescription}>
                            {criteria}
                        </span>
                    </li>
                );
            })}
        </ul>
    );
};

const FilesUploadStatusWrapper = ({
    referenceFilesToUpload,
    referenceFilesState,
    referenceFileUploadingState,
    referenceFilesUploadingStatusState,
    referenceFilesFailedToUploadState,
    onDiscardUploadedReferenceFilesHandler,
    onDiscardErrorFileHandler,
    onRemoveUploadedFileHandler
}) => {
    return (
        <div className={styles.Container}>
            {referenceFilesToUpload.map((file, fileIndex) => {
                let progress = file.progress;
                if (
                    file.fileName === referenceFileUploadingState.data.fileName
                ) {
                    progress = referenceFileUploadingState.data.progress;
                }
                if (
                    referenceFilesState.findIndex(
                        (referenceFile) =>
                            referenceFile.fileName === file.fileName
                    ) > -1
                ) {
                    progress = 100;
                }

                if (
                    referenceFilesFailedToUploadState.findIndex(
                        (faileFile) => faileFile.fileName === file.fileName
                    ) > -1
                ) {
                    file.error.failedToUploadFile = true;
                }

                file = {
                    ...file,
                    progress
                };
                return (
                    <FileUploadStatus
                        key={fileIndex}
                        {...file}
                        referenceFilesUploadingStatusState={
                            referenceFilesUploadingStatusState
                        }
                        onDiscardErrorFileHandler={onDiscardErrorFileHandler}
                        onRemoveUploadedFileHandler={
                            onRemoveUploadedFileHandler
                        }
                    />
                );
            })}

            <UploadFileCriteria />

            <Button
                id={'discard-reference-file-upload'}
                className={styles.DiscardButton}
                variant="outlined"
                color="secondary"
                disabled={
                    referenceFilesUploadingStatusState.loading ||
                    !referenceFilesUploadingStatusState.success ||
                    referenceFilesState.length === 0
                }
                onClick={() => onDiscardUploadedReferenceFilesHandler()}>
                Discard
            </Button>
        </div>
    );
};

export default FilesUploadStatusWrapper;
