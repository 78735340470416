import React from 'react';
import Button from '@material-ui/core/Button';
import styles from './FileChooser.module.css';

const FileChooser = (props) => {
    const onChange = (e) => {
        props.onFilesBrowserSelection(e.target.files);
        e.target.value = '';
    };

    return (
        <div>
            <React.Fragment>
                <label htmlFor="file-upload" className={styles.BrowseFileLabel}>
                    <Button
                        disabled={
                            props.presentationFileUploadingStatusState.loading
                        }
                        variant="contained"
                        color="primary"
                        component="span">
                        Browse
                    </Button>
                </label>
                <input
                    id="file-upload"
                    type="file"
                    accept=".pdf,.doc,.docx,.ppt,.pptx,.key,.mp4,.mov,.webm"
                    multiple
                    onChange={onChange}
                    disabled={
                        props.presentationFileUploadingStatusState.loading
                    }
                />
            </React.Fragment>
        </div>
    );
};

export default FileChooser;
