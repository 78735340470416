import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { setDocumentPageCount } from '../../redux/action/Index';
import { onPageChangeClick } from '../../redux/action/Index';
import { currentTimeStamp } from '../../redux/action/Index';
import { onSaveRecordedVideo } from '../../redux/action/Index';
import { onPageUpdate } from '../../redux/action/Index';
import { setVideoPreviewToFalse } from '../../redux/action/Index';
import { onPageChangeTimeStampRecordAction } from '../../redux/action/Index';
import { onResetRecordedTimeStampAction } from '../../redux/action/Index';
import { onSetRecordedVideoDurationAction } from '../../redux/action/Index';
import { onResetRecordedVideoDurationAction } from '../../redux/action/Index';
import { onResetPDFPageNumberAction } from '../../redux/action/Index';
import VideoPlayer from '../VideoRecorder/VideoPlayer';
import PDFViewComponent from '../PDFViewComponent/PDFViewComponent';
import { withRouter } from 'react-router';
import ButtonComp from '../ButtonComp/ButtonComp';
import { getQueryParam } from '../../utils/router';
import { Grid } from '@material-ui/core';
import styles from './RecordingPage.module.scss';
import { OnlineDetector } from 'components/Shared/UI';
import { WarningAlert } from 'components/Shared/Theme';
import '../../style/components/RecordingPage.scss';

class RecordingPage extends Component {
    state = {
        isEditing: false,
        secondsPlayed: 0,
        show5MinRemainingAlert: false,
        isInternetOnline: true,
        isRecordingPaused: false
    };

    isInEditingMode = () => {
        let isEditing = getQueryParam(
            'isEditing',
            this.props.history.location.search
        );
        // eslint-disable-next-line eqeqeq
        if (isEditing && isEditing.toLowerCase() == 'true') {
            this.setState({ isEditing: true });
        }
    };

    componentDidMount() {
        this.props.onResetRecordedVideoDurationAction();
        this.props.onResetRecordedTimeStampAction();
        this.props.onResetPDFPageNumberAction();
        this.isInEditingMode();
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.history.location.search !==
            prevProps.history.location.search
        ) {
            this.isInEditingMode();
        }

        const {
            state: { initialData }
        } = this.props;
        const { presentationLength } = initialData;
        const { secondsPlayed, show5MinRemainingAlert } = this.state;

        if (
            secondsPlayed >= presentationLength * 60 - 300 &&
            !show5MinRemainingAlert
        ) {
            this.setState({ show5MinRemainingAlert: true });
        }
    }

    continueEditing = () => {
        this.props.history.goBack();
    };

    onPageChangeTimeStampRecord = (pageNumber) => {
        const { isRecordingPaused } = this.state;
        if (!isRecordingPaused) {
            this.props.onPageChangeTimeStampRecordAction(
                pageNumber,
                this.state.secondsPlayed
            );
        }
    };

    setSecondsPlayed = (secondsPlayed) => {
        this.setState({ secondsPlayed });
    };

    onResetParentState = () => {
        this.setState((state) => ({
            secondsPlayed: 0,
            show5MinRemainingAlert: false
        }));
    };

    onOnlineStatusChangeHandler = (status) => {
        this.setState({ isInternetOnline: status });
    };

    onRecordingPauseChangeHandler = (status) => {
        this.setState({ isRecordingPaused: status });
    };

    onRecordingResumeTimestampRecordHandler = () => {
        this.props.onPageChangeTimeStampRecordAction(
            this.props.currentPresentationPage,
            this.state.secondsPlayed
        );
    };

    render() {
        const {
            state: { initialData }
        } = this.props;
        const { recordedVideoUploadState } = this.props;
        const { show5MinRemainingAlert } = this.state;
        const { isInternetOnline } = this.state;
        // eslint-disable-next-line no-unused-vars
        const { classes } = this.props;

        let nextPageActionUI = null;

        nextPageActionUI = (
            <>
                {!this.state.isEditing ? (
                    <ButtonComp
                        id={'NextButtonAction'}
                        btnDisable={
                            !initialData.videoPreview ||
                            recordedVideoUploadState.loading ||
                            recordedVideoUploadState.error
                        }
                        handleClick={() =>
                            this.props.history.push('/presentation-category')
                        }
                        className={!initialData.videoPreview ? 'disabled' : ''}
                        disabled={true}
                        name={'Next'}
                        style={{ width: '40%' }}
                    />
                ) : (
                    <ButtonComp
                        btnDisable={
                            !initialData.videoPreview ||
                            recordedVideoUploadState.loading ||
                            recordedVideoUploadState.error
                        }
                        handleClick={() => this.continueEditing()}
                        className={!initialData.videoPreview ? 'disabled' : ''}
                        disabled={true}
                        name={'Exit'}
                        style={{ width: '40%' }}
                    />
                )}
            </>
        );

        return (
            <>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center">
                    <Grid item sm={11} md={11} lg={10} xl={10}>
                        <Grid container direction="row">
                            {!isInternetOnline ? (
                                <Grid item sm={12} md={12} lg={12}>
                                    <div
                                        className={`${styles.TimeRemainingAlert}`}>
                                        <WarningAlert
                                            message={
                                                'You are offline. Retry to upload the recording once you are back online'
                                            }
                                        />
                                    </div>
                                </Grid>
                            ) : null}

                            {show5MinRemainingAlert ? (
                                <Grid item sm={12} md={12} lg={12}>
                                    <div
                                        className={`${styles.TimeRemainingAlert}`}>
                                        <WarningAlert
                                            message={
                                                'Your module will end in 5 minutes.'
                                            }
                                        />
                                    </div>
                                </Grid>
                            ) : null}

                            <Grid item sm={8} md={8} lg={8}>
                                <div style={{ padding: '8px' }}>
                                    <div
                                        style={{
                                            border: '2px solid #edf2f9',
                                            borderRadius: '5px',
                                            width: '100%'
                                        }}>
                                        <PDFViewComponent
                                            isRecordingEnabled={true}
                                            onPageChangeTimeStampRecord={
                                                this.onPageChangeTimeStampRecord
                                            }
                                            {...this.props}
                                        />
                                    </div>

                                    <div
                                        style={{
                                            alignItems: 'right',
                                            textAlign: 'right'
                                        }}>
                                        {nextPageActionUI}
                                    </div>
                                </div>
                            </Grid>
                            <Grid item sm={4} md={4} lg={4}>
                                <div style={{ padding: '8px' }}>
                                    <div
                                        style={{
                                            border: '2px solid #edf2f9',
                                            borderRadius: '5px'
                                        }}>
                                        <VideoPlayer
                                            {...this.props}
                                            onSecondsPlayed={
                                                this.setSecondsPlayed
                                            }
                                            onPageStartTimeStampRecord={() =>
                                                this.onPageChangeTimeStampRecord(
                                                    this.props
                                                        .currentPresentationPage
                                                )
                                            }
                                            presentationLength={
                                                initialData.presentationLength
                                            }
                                            disableRecordAgain={
                                                !initialData.videoPreview ||
                                                recordedVideoUploadState.loading
                                            }
                                            isInternetOnline={isInternetOnline}
                                            recordedVideoUploadErrorState={
                                                recordedVideoUploadState.error
                                            }
                                            onResetParentState={
                                                this.onResetParentState
                                            }
                                            onRecordingPauseChangeHandler={
                                                this
                                                    .onRecordingPauseChangeHandler
                                            }
                                            onRecordingResumeTimestampRecordHandler={
                                                this
                                                    .onRecordingResumeTimestampRecordHandler
                                            }
                                        />
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <OnlineDetector onChange={this.onOnlineStatusChangeHandler} />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        state: state.reducer,
        recordedVideoUploadState: state.reducer.initialData.recordedVideoUpload,
        currentPresentationPage: state.reducer.initialData.pageNumber
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setDocumentPageCount,
            onPageChangeClick,
            currentTimeStamp,
            onSaveRecordedVideo,
            onPageUpdate,
            setVideoPreviewToFalse,
            onPageChangeTimeStampRecordAction,
            onResetRecordedTimeStampAction,
            onSetRecordedVideoDurationAction,
            onResetRecordedVideoDurationAction,
            onResetPDFPageNumberAction
        },
        dispatch
    );
}

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(RecordingPage);
