import React, { useState } from 'react';
import ReactMapGL, { Marker, FlyToInterpolator } from 'react-map-gl';
import useSupercluster from 'use-supercluster';
import { useRef } from 'react';

const LocationMap = (props) => {
    const { points } = props;

    const accessToken =
        'pk.eyJ1IjoiYmVha2VyLWtrYWh3dGQiLCJhIjoiY2tmcHg2MzRtMDhwejJ3b2YxZ2FzMmVtZiJ9.ihaqXnxm7Mv-EzITRUJKxg';
    const [viewport, setViewport] = useState({
        latitude: 34.8951,
        longitude: -100.0364,
        width: '100%',
        height: '65vh',
        zoom: 4
    });
    const mapRef = useRef();

    const bounds =
        mapRef && mapRef.current
            ? mapRef.current.getMap().getBounds().toArray().flat()
            : null;

    const { clusters, supercluster } = useSupercluster({
        points,
        bounds,
        zoom: viewport.zoom,
        options: { radius: 75, maxZoom: 20 }
    });

    const setViewportHandler = (newViewport) => {
        if (mapRef.current) {
            setViewport(newViewport);
        }
    }

    return (
        <ReactMapGL
            {...viewport}
            maxZoom={5}
            mapboxApiAccessToken={accessToken}
            onViewportChange={(newViewport) => setViewportHandler({ ...newViewport })}
            ref={mapRef} >
            {
                clusters.map((cluster) => {
                    const [longitude, latitude] = cluster.geometry.coordinates;
                    const { cluster: isCluster, point_count: pointCount } =
                        cluster.properties;

                    if (isCluster) {
                        return (
                            <Marker
                                key={`cluster-${cluster.id}`}
                                latitude={latitude}
                                longitude={longitude}>
                                <div
                                    className="cluster-marker"
                                    style={{
                                        width: `${10 +
                                            (pointCount / points.length) * 20
                                            }px`,
                                        height: `${10 +
                                            (pointCount / points.length) * 20
                                            }px`
                                    }}
                                    onClick={() => {
                                        const expansionZoom = Math.min(
                                            supercluster.getClusterExpansionZoom(
                                                cluster.id
                                            ),
                                            20
                                        );

                                        setViewport({
                                            ...viewport,
                                            latitude,
                                            longitude,
                                            zoom: expansionZoom,
                                            transitionInterpolator:
                                                new FlyToInterpolator({
                                                    speed: 2
                                                }),
                                            transitionDuration: 'auto'
                                        });
                                    }}>
                                    {pointCount}
                                </div>
                            </Marker>
                        );
                    }

                    return (
                        <Marker
                            key={`presentation-${cluster.properties.mapId}`}
                            latitude={latitude}
                            longitude={longitude}>
                            <button className="map-marker">
                                <span></span>
                            </button>
                        </Marker>
                    );
                })
            }
        </ReactMapGL >
    );
};

export default LocationMap;