import React, { Component } from 'react';
import RecordRTC from 'recordrtc';
import { Button, Dialog, DialogContent } from '@material-ui/core';
import { USER_MEDIA_UNAVAILABLE } from 'utils/error-constants';
import { MEDIA_RECORDER_ERROR } from 'utils/error-constants';
import { getDurationFromSeconds } from 'utils/time';
import VideoContainer from './VideoContainer/VideoContainer';
import RecorderActions from './RecorderActions/RecorderActions';
import _ from 'lodash';

const hasGetUserMedia = !!(
    navigator.getUserMedia ||
    navigator.webkitGetUserMedia ||
    navigator.mozGetUserMedia ||
    navigator.msGetUserMedia
);

class VideoPlayer extends Component {
    constructor(props) {
        super(props);
        this.localVideoRef = React.createRef();
        this.recordedVideoRef = React.createRef();
        this.state = {
            recordVideo: null,
            src: null,
            uploadSuccess: null,
            uploading: false,
            timer: 0,
            duration: '00:00:00',
            durationInSeconds: 0,
            streaming: false,
            initRecordingPage: 1,
            pauseResume: false,
            cameraAccess: false,
            reload: true,
            dialog: false,
            dateStarted: '',
            disableRecording: false,
            replayBlob: null,
            userMediaError: null,
            hasVideoUploadError: null,
            isVideoReadyForStreaming: false
        };
    }

    componentDidMount() {
        if (!hasGetUserMedia) {
            alert(
                'Your browser cannot stream from your webcam. Please switch to Chrome or Firefox.'
            );
            return;
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.state.initialData.pageNoChange && this.state.streaming) {
            this.props.currentTimeStamp(
                this.localVideoRef,
                this.state.recordVideo
            );
        }

        const { recordedVideoUploadErrorState: prevVideoUploadError } =
            prevProps;
        const { recordedVideoUploadErrorState: currentVideoUploadError } =
            this.props;
        let hasVideoUploadError = null;
        let shouldUpdateState = false;

        if (
            !_.isEqual(prevVideoUploadError, currentVideoUploadError) &&
            currentVideoUploadError &&
            currentVideoUploadError.message === 'Network Error'
        ) {
            hasVideoUploadError = 'Network Error';
            shouldUpdateState = true;
        } else if (
            !_.isEqual(prevVideoUploadError, currentVideoUploadError) &&
            !currentVideoUploadError
        ) {
            shouldUpdateState = true;
        }

        if (shouldUpdateState) {
            this.setState((state) => ({ ...state, hasVideoUploadError }));
        }
    }

    setUserMediaError = (error) => {
        this.setState((state) => ({
            ...state,
            userMediaError: error
        }));
    };

    requestUserMedia(props) {
        const { initialData } = props.state;
        this.setState({
            cameraAccess: true,
            initRecordingPage: initialData.pageNumber
        });
        // requestUserMedia
        const constraints = {
            video: true,
            audio: true
        };
        const success = (stream) => {
            // Now streaming
            this.localVideoRef.current.srcObject = stream;
            this.localVideoRef.current.onloadedmetadata = (e) => {
                this.setState({ isVideoReadyForStreaming: true });
            };
        };
        const failure = (error) => {
            this.setUserMediaError(String(error));
        };
        navigator.mediaDevices
            .getUserMedia(constraints)
            .then(success)
            .catch(failure);
        return;
    }

    startRecord() {
        const { userMediaError } = this.state;
        if (!this.localVideoRef.current.srcObject) {
            this.setUserMediaError(USER_MEDIA_UNAVAILABLE);
            return;
        } else if (this.localVideoRef.current.srcObject.active === false) {
            this.setUserMediaError(USER_MEDIA_UNAVAILABLE);
            return;
        } else if (userMediaError === USER_MEDIA_UNAVAILABLE) {
            this.setUserMediaError(null);
        }
        this.props.onResetRecordedTimeStampAction();
        this.props.onPageStartTimeStampRecord();

        try {
            this.setState(
                {
                    streaming: true,
                    reload: false,
                    duration: '00:00:00',
                    durationInSeconds: 0,
                    dateStarted: new Date().getTime(),
                    pauseResume: false,
                    recordVideo: RecordRTC(
                        this.localVideoRef.current.srcObject,
                        {
                            type: 'video',
                            mimeType: 'video/webm;codecs=vp8,opus',
                            timeSlice: 1000,
                            onTimeStamp: (timestamp, timestamps) => {
                                const { dateStarted } = this.state;
                                const currentTimeInMiliseconds =
                                    new Date().getTime();

                                const duration = Math.floor(
                                    (currentTimeInMiliseconds - dateStarted) /
                                        1000
                                );

                                if (duration < 0 || this.state.pauseResume) {
                                    return;
                                }

                                const sec_num = parseInt(duration, 10);

                                // Presentation Length is in minutes so convert it to seconds
                                if (
                                    this.props.presentationLength &&
                                    sec_num >=
                                        this.props.presentationLength * 60
                                ) {
                                    this.stopRecord(this.props);
                                    this.setState({ disableRecording: true });
                                }

                                const durationForUI =
                                    getDurationFromSeconds(sec_num);
                                this.setState({
                                    duration: durationForUI,
                                    durationInSeconds: sec_num
                                });
                                this.props.onSecondsPlayed(sec_num);
                            }
                        }
                    )
                },
                () => {
                    this.state.recordVideo.startRecording();
                }
            );
        } catch (error) {
            this.setUserMediaError(MEDIA_RECORDER_ERROR);
        }
    }

    stopRecord(props) {
        this.props.onSetRecordedVideoDurationAction(
            this.state.durationInSeconds
        );

        try {
            this.state.recordVideo.stopRecording(() => {
                const { recordVideo } = this.state;

                const params = {
                    type: 'video/webm;codecs=vp8,opus',
                    data: recordVideo.blob,
                    id: Math.floor(Math.random() * 90000) + 10000
                };

                if (!this.state.reload) {
                    this.setState({ uploading: true, streaming: false });
                }
                props.onSaveRecordedVideo(
                    params,
                    this.state.initRecordingPage,
                    this.state.reload
                );

                this.setState({
                    replayBlob: { url: URL.createObjectURL(recordVideo.blob) }
                });
                this.props.onResetParentState();
            });
            this.localVideoRef.current.srcObject
                .getTracks()
                .forEach((track) => {
                    track.stop();
                });
            this.setState({ isVideoReadyForStreaming: false });
        } catch (error) {
            console.log('Error while stopping the recorder, error');
        }
    }

    cleanUserMediaResources = () => {
        try {
            const { recordVideo } = this.state;
            if (recordVideo) {
                recordVideo.stopRecording();
            }

            const { current } = this.localVideoRef;
            if (current && current.srcObject) {
                current.srcObject.getTracks().forEach((track) => {
                    track.stop();
                });
            }
        } catch (error) {
            console.log('Error while cleaning the user media stream', error);
        }
    };

    reloadRecord = (props) => {
        this.props.onResetRecordedVideoDurationAction();
        this.setState({ disableRecording: false });
        if (!this.state.uploading) {
            this.stopRecord(props);
        }
        this.requestUserMedia(props);
        this.setState({
            reload: true,
            uploading: false,
            duration: '00:00:00',
            durationInSeconds: 0,
            pauseResume: true,
            streaming: true,
            dateStarted: new Date().getTime()
        });
        props.onResetRecordedTimeStampAction();
    };

    pauseResumeRecord(props) {
        const currentTimeInMiliseconds = new Date().getTime();
        if (this.state.reload === true) {
            return this.startRecord();
        }
        const { pauseResume } = this.state;

        this.setState((state) => ({
            dateStarted: currentTimeInMiliseconds - state.dateStarted,
            pauseResume: !state.pauseResume
        }));

        if (!this.localVideoRef.current.paused) {
            this.localVideoRef.current.pause();
        } else {
            this.localVideoRef.current.play();
        }

        if (!pauseResume) {
            this.state.recordVideo.pauseRecording();
            this.localVideoRef.current.srcObject
                .getTracks()
                .forEach((track) => {
                    track.enabled = false;
                });
            this.props.onRecordingPauseChangeHandler(true);
        } else {
            this.state.recordVideo.resumeRecording();
            this.localVideoRef.current.srcObject
                .getTracks()
                .forEach((track) => {
                    track.enabled = true;
                });
            this.props.onRecordingPauseChangeHandler(false);
            this.props.onRecordingResumeTimestampRecordHandler();
        }
    }

    onOpenDialogHandler = () => {
        this.setState({ dialog: true });
    };

    componentWillUnmount() {
        this.cleanUserMediaResources();
    }

    render() {
        const { initialData } = this.props.state;
        const { recordedVideoS3 } = initialData;
        const { disableRecording } = this.state;
        const { cameraAccess } = this.state;
        const { streaming } = this.state;
        const { uploading } = this.state;
        const { replayBlob } = this.state;
        const { pauseResume } = this.state;
        const { userMediaError } = this.state;
        const { hasVideoUploadError } = this.state;
        const { isVideoReadyForStreaming } = this.state;
        const { isInternetOnline } = this.props;
        const videoPreviewSrc =
            replayBlob && !_.isEmpty(recordedVideoS3)
                ? replayBlob
                : recordedVideoS3;

        return (
            <div style={{ height: '100%' }}>
                <div className="recordingpage__video">
                    <div className="recordingpage__video__top">
                        <VideoContainer
                            {...this.props}
                            requestUserMedia={() =>
                                this.requestUserMedia(this.props)
                            }
                            localVideoRef={this.localVideoRef}
                            duration={this.state.duration}
                            onOpenDialogHandler={this.onOpenDialogHandler}
                            videoPreviewSrc={videoPreviewSrc}
                            initialData={initialData}
                            cameraAccess={cameraAccess}
                            streaming={streaming}
                            uploading={uploading}
                            userMediaError={userMediaError}
                            hasVideoUploadError={hasVideoUploadError}
                            isInternetOnline={isInternetOnline}
                        />
                    </div>
                    <div className="recordingpage__video__bottom">
                        <RecorderActions
                            cameraAccess={cameraAccess}
                            streaming={streaming}
                            uploading={uploading}
                            startRecord={() => this.startRecord(this.props)}
                            stopRecord={() => this.stopRecord(this.props)}
                            disableRecording={disableRecording}
                            disableRecordAgain={this.props.disableRecordAgain}
                            isInternetOnline={isInternetOnline}
                            hasVideoUploadError={hasVideoUploadError}
                            onOpenDialogHandler={this.onOpenDialogHandler}
                            pauseResume={pauseResume}
                            isVideoReadyForStreaming={isVideoReadyForStreaming}
                            pauseResumeRecord={() =>
                                this.pauseResumeRecord(this.props)
                            }
                        />
                    </div>
                </div>

                <Dialog
                    open={this.state.dialog}
                    onClose={() => this.setState({ dialog: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogContent>
                        <p>Record this video again?</p>
                        <div className="dialog__button">
                            <Button
                                className="no"
                                style={{ marginRight: '23px' }}
                                onClick={() => {
                                    this.setState({ dialog: false });
                                }}>
                                No
                            </Button>
                            <Button
                                className="yes"
                                onClick={() => {
                                    this.reloadRecord(this.props);
                                    this.props.setVideoPreviewToFalse();
                                    this.setState({ dialog: false });
                                }}>
                                Yes
                            </Button>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

export default VideoPlayer;
