import React from 'react';
import Box from '@material-ui/core/Box';
import { DataGrid } from '@material-ui/data-grid';
import { Rating } from '@material-ui/lab';
import { GridOverlay } from '@material-ui/data-grid';
import { Grid } from '@material-ui/core';
import { AiOutlineEye } from 'react-icons/ai';
import styles from './PresentationsDatagrid.module.css';
import LadyStandingImage from 'assets/images/no-result/lady-standing.svg';
import Presentation from './model';
import defaultPresentationThumbnailImage from 'assets/images/samples/sample-dashboard-video.png';
const presentationThumbnailImage = (id) =>
    `${process.env.REACT_APP_PRESENTATION_THUMBNAILS_URL}/thumbnails/presentations/${id}/thumbnail.jpeg`;

const CustomNoRowsOverlay = (props) => {
    const classes = props.classes;

    return (
        <GridOverlay className={classes.root}>
            <div className="container mx-auto -lg:px-8 -md:px-4 flex flex-col justify-center items-center max-w-screen-lg text-center">
                <img
                    className={`my-12`}
                    src={LadyStandingImage}
                    alt="No Result User"
                />

                <p className={`${styles.NoResultMessage} text-center`}>
                    You do not have any reviews yet
                </p>
            </div>
        </GridOverlay>
    );
};

const PresentationsDatagrid = (props) => {
    const { presentationsState } = props;
    const { loading } = presentationsState;
    // const { success } = presentationsState;
    // const { error } = presentationsState;
    const { data } = presentationsState;

    const columns = [
        {
            field: 'presentations',
            headerName: 'Modules',
            width: 500,
            renderCell: (params) => {
                const {
                    id,
                    title,
                    viewCount,
                    reviewCount,
                    featuredReview,
                    createdDate
                } = params.row;
                const presentation = new Presentation(
                    id,
                    title,
                    viewCount,
                    reviewCount,
                    featuredReview,
                    createdDate
                );

                return (
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        style={{ padding: 5 }}
                        alignItems="center">
                        <Grid item lg={3}>
                            <div className={styles.DatagridPresentationImage}>
                                <img
                                    style={{
                                        width: 'auto',
                                        height: '100%',
                                        maxWidth: '100%',
                                        maxHeight: 65
                                    }}
                                    alt="Module Thumbnail"
                                    src={presentationThumbnailImage(
                                        params.row.id
                                    )}
                                    onError={(e) => {
                                        e.target.onError = null;
                                        e.target.src =
                                            defaultPresentationThumbnailImage;
                                    }}></img>
                            </div>
                        </Grid>
                        <Grid item lg={9}>
                            <Box component="div" style={{ paddingLeft: 15 }}>
                                <span
                                    className={`${styles.PresentationsColumnSpan} ${styles.PresentationsColumnSpanTitle}`}>
                                    {presentation.title}
                                </span>
                                <span
                                    className={`${styles.PresentationsColumnSpan} ${styles.PresentationsColumnSpanDate}`}>
                                    {presentation.createdDate}
                                </span>
                            </Box>
                        </Grid>
                    </Grid>
                );
            },
            disableColumnMenu: true,
            disableColumnFilter: true,
            sortable: false
        },
        {
            field: 'overallReviews',
            headerName: 'Overall Reviews',
            width: 200,
            renderCell: (params) => {
                const {
                    id,
                    title,
                    viewCount,
                    reviewCount,
                    featuredReview,
                    createdDate
                } = params.row;
                const presentation = new Presentation(
                    id,
                    title,
                    viewCount,
                    reviewCount,
                    featuredReview,
                    createdDate
                );

                return (
                    <div className={styles.Reviews}>
                        <Rating
                            name="custom-rating-filter-operator"
                            placeholder="Filter value"
                            disabled
                            value={Number(presentation.featuredReview.starsAvg)}
                            precision={0.5}
                        />
                    </div>
                );
            },
            disableColumnMenu: true,
            disableColumnFilter: true,
            sortable: false
        },
        {
            field: 'reviews',
            headerName: 'Reviews',
            width: 150,
            renderCell: (params) => {
                const {
                    id,
                    title,
                    viewCount,
                    reviewCount,
                    featuredReview,
                    createdDate
                } = params.row;
                const presentation = new Presentation(
                    id,
                    title,
                    viewCount,
                    reviewCount,
                    featuredReview,
                    createdDate
                );

                return (
                    <span className={styles.DurationSpan}>
                        {presentation.reviewCount}
                    </span>
                );
            },
            disableColumnMenu: true,
            disableColumnFilter: true,
            sortable: false
        },
        {
            field: 'views',
            headerName: 'Views',
            width: 150,
            renderCell: (params) => {
                const {
                    id,
                    title,
                    viewCount,
                    reviewCount,
                    featuredReview,
                    createdDate
                } = params.row;
                const presentation = new Presentation(
                    id,
                    title,
                    viewCount,
                    reviewCount,
                    featuredReview,
                    createdDate
                );

                return (
                    <span className={styles.ViewsSpan}>
                        {presentation.viewCount}
                    </span>
                );
            },
            disableColumnMenu: true,
            disableColumnFilter: true,
            sortable: false
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 150,
            renderCell: (params) => {
                const {
                    id,
                    title,
                    viewCount,
                    reviewCount,
                    featuredReview,
                    createdDate
                } = params.row;
                const presentation = new Presentation(
                    id,
                    title,
                    viewCount,
                    reviewCount,
                    featuredReview,
                    createdDate
                );

                return (
                    <div className={styles.PresentationColumnReplayContainer}>
                        <AiOutlineEye
                            size={'2em'}
                            color={'rgba(0, 0, 0, 0.6)'}
                            className={`${styles.ViewPresentationReviews}`}
                            aria-hidden="true"
                            onClick={() =>
                                props.onViewPresentationReviewsHandler(
                                    presentation.id
                                )
                            }
                        />
                    </div>
                );
            },
            disableColumnMenu: true,
            disableColumnFilter: true,
            sortable: false
        }
    ];

    return (
        <DataGrid
            rows={data || []}
            columns={columns}
            rowHeight={75}
            // hideFooter={true}
            disableSelectionOnClick
            pageSize={6}
            autoPageSize
            showCellRightBorder={false}
            showColumnRightBorder={false}
            loading={loading}
            components={{
                NoRowsOverlay: CustomNoRowsOverlay.bind(this, props)
            }}
        />
    );
};

export default PresentationsDatagrid;
