import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import LocationMap from 'components/Dashboard/LocationMap';
import styles from './ReactTab.module.css';
import { useDispatch, useSelector } from "react-redux";
import { getPresentations } from "redux/action/Index";
import { BiInfoCircle } from "react-icons/bi";
import { makeStyles } from '@material-ui/core/styles';
import Loader from 'components/LoaderComp/LoaderComp';
import { CenterWrapper } from 'components/Shared/HOC';
import { P } from 'components/Shared/Theme';

const useStyles = makeStyles((theme) => ({
    inputSelect: {
        '& .MuiOutlinedInput-input': {
            padding: '10.5px 14px'
        },
        color: '#283E59',
        fontSize: '18px',
        fontWeight: 600
    }
}));

const ReachTab = (props) => {

    const presentationsState = useSelector(
        (state) => state.reducer.initialData.presentationsData
    );

    const dispatch = useDispatch();
    const classes = useStyles();

    useEffect(() => {
        dispatch(getPresentations());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { data: presentationData } = presentationsState;
    const { loading } = presentationsState;
    // const { error } = presentationsState;
    const { success } = presentationsState;

    const presData = presentationData || [];
    const cleanup = presData.filter((item) => {
        if (item.geoDataSet) {
            return true;
        }
        return false;
    });

    let data = [];

    cleanup.forEach((item) => {
        if (item.geoDataSet.length > 0) {
            for (let x of item.geoDataSet) {
                try {
                    const parsed = JSON.parse(x);
                    if (parsed.city) {
                        data.push(parsed);
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        } else {
            try {
                const parsed = JSON.parse(item.geoDataSet);
                if (parsed.city) {
                    data.push(parsed);
                }
            } catch (error) {
                console.log(error);
            }
        }
    });

    let viewerLocations = [];
    let points = [];

    if (data && data.length > 0) {
        let tempViews = {};
        for (let val of data) {
            const newKey = val.city + ', ' + val.countryRegion;
            if (tempViews[newKey]) {
                tempViews[newKey] += 1;
            } else {
                tempViews[newKey] = 1;
            }
        }

        viewerLocations = Object.keys(tempViews)
            .map((key) => {
                return { location: key, viewerCount: tempViews[key] };
            })
            .sort(function (a, b) {
                return a.viewerCount > b.viewerCount;
            });

        const presentationUser = data ? data.slice(0, 2000) : [];
        points = presentationUser.map((mapData) => ({
            type: 'Feature',
            properties: {
                cluster: false,
                mapId: mapData.id
            },
            geometry: {
                type: 'Point',
                coordinates: [parseFloat(mapData.lon), parseFloat(mapData.lat)]
            }
        }));
    }

    const viewerLocationDuration = [
        { label: 'Last 30 days', value: 'last30Days' }
    ];

    let containerUI = (
        <CenterWrapper>
            <Loader />
        </CenterWrapper>
    );

    if (!loading && success) {
        containerUI = (
            <Grid container direction="row" justify="center">

                <Grid item lg={12}>
                    <p className={styles.Title}>Reach</p>
                </Grid>

                <Grid item lg={12} className={styles.ReportContainer}>
                    <Grid container direction="row"
                        justify="center">
                        <Grid item lg={8}>
                            {points ?
                                <div>
                                    <LocationMap points={points} />
                                </div>
                                : null}
                        </Grid>
                        <Grid item xs={4} lg={4} style={{ paddingLeft: 50, paddingRight: 100 }}>
                            <p className={styles.ViewerLocationTitle}>Viewer Location</p>
                            <FormControl variant="outlined" fullWidth>
                                <Select
                                    native
                                    style={{
                                        color: '#283E59',
                                        fontSize: '18px',
                                        fontWeight: 600
                                    }}
                                    className={classes.inputSelect}
                                    // value={state.age}
                                    // onChange={handleChange}
                                    inputProps={{
                                        name: 'age',
                                        id: 'outlined-age-native-simple',
                                    }}>

                                    {viewerLocationDuration.map((locationDuration, index) => (
                                        <option key={index} value={locationDuration.value}>{locationDuration.label}</option>
                                    ))}
                                </Select>
                            </FormControl>

                            <ul style={{ padding: 0 }}>
                                {viewerLocations.map((viewerLocation, index) => (
                                    <li key={index} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <P inline="true" className={styles.LocationLabel}>{viewerLocation.location}</P>
                                        <P inline="true" className={styles.LocationCount}>{viewerLocation.viewerCount}</P>
                                    </li>
                                ))}

                                {!viewerLocations ||
                                    (viewerLocations instanceof Array &&
                                        viewerLocations.length === 0) ? (
                                    <div>
                                        <div className={`${styles.NoViewersIconContainer}`}>
                                            <BiInfoCircle
                                                size={'1.8em'}
                                                color={'rgba(40, 62, 89, 0.54)'}
                                            />
                                        </div>

                                        <div
                                            className={`${styles.NoViewersMessageContainer}`}>
                                            <P inline="true" className={`${styles.NoViewersMessage}`}>
                                                No Viewers yet
                                            </P>
                                        </div>
                                    </div>
                                ) : null}
                            </ul>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    return (containerUI);
}

export default ReachTab;