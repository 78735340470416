import { createStore, applyMiddleware  } from 'redux';
import CombineReducers from './reducers/CombineReducers';
import thunk from 'redux-thunk';
import { compose } from 'redux';

const composeEnhancers = process.env.NODE_ENV === 'development' ? (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose) : compose;
let middleware = [thunk];
if (process.env.NODE_ENV !== 'production') {
    const logger = require('redux-logger').default;
    middleware = [...middleware, logger];
}

const store = createStore(
    CombineReducers,
    composeEnhancers(applyMiddleware(...middleware))
);

export default store;
