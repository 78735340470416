import React from 'react';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';

class KeywordsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            typeValue: ''
        };
    }

    handleInputChange(event) {
        this.setState({ typeValue: event.target.value });
    }

    handleKeyPress(event) {
        if (event.key === 'Enter') {
            if (!event.target.value.trim().length) {
                this.setState({ typeValue: '' })
            } else {
                this.props.addNewKeyword(event.target.value)
                this.setState({ typeValue: '' })
            }
        }
    }

    render() {
        const { initialData } = this.props.state

        return (
            <>
                <div className="keyword__chip">
                    {initialData.keywordsValue.length > 0 ?
                        initialData.keywordsValue.map((x, i) => (
                            <Chip
                                key={Math.random()}
                                label={x.word}
                                onDelete={() => this.props.onRemoveKeyWord(i)}
                                deleteIcon={
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="24" height="24" rx="6" fill="#D2DDEC" />
                                        <path d="M8 8L12 12M12 12L16 8M12 12L8 16M12 12L16 16" stroke="#283E59" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                }
                            />
                            // <div className='keywords' key={Math.random()}>
                            //   {x.word}
                            //   <i className="fa fa-times closeKey" aria-hidden="true" onClick={() => this.props.onRemoveKeyWord(i) } ></i>
                            // </div>
                        ))
                        : null
                    }
                </div>
                <TextField
                    id="KeywordSelection"
                    placeholder="keywords"
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={this.state.typeValue}
                    onChange={(event) => this.handleInputChange(event)}
                    onKeyPress={(event) => this.handleKeyPress(event)}
                    variant="outlined"
                />
                {/* <input
            className=""
            value= {this.state.typeValue}
            placeholder="keywords"
            onChange={(event) => this.handleInputChange(event) }
            onKeyPress={(event) => this.handleKeyPress(event) }
          /> */}
            </>
        );
    }
}

export default KeywordsComponent;