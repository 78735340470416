import React, { useState } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import { Rating } from '@material-ui/lab';
import { GridOverlay } from '@material-ui/data-grid';
import styles from './ReviewsDatagrid.module.css';
import ReviewDetail from './ReviewDetail';
import LadyStandingImage from 'assets/images/no-result/lady-standing.svg';
import { ProfilePicture } from 'components/Shared/UI';
import { P } from 'components/Shared/Theme';
import Review from './model';
import { getSortedAscReviews } from './utils';
import { useGridSlotComponentProps } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/styles';
import TablePagination from '@material-ui/core/TablePagination';
import { Grid } from "@material-ui/core";

const useStyles = makeStyles({
    root: {
        display: 'flex',
        '& .MuiTablePagination-selectRoot': {
            display: 'none'
        }
    }
});

const CustomNoRowsOverlay = (props) => {
    const classes = props.classes;

    return (
        <GridOverlay className={classes.root}>
            <div className="container mx-auto -lg:px-8 -md:px-4 flex flex-col justify-center items-center max-w-screen-lg text-center">
                <img className={`my-12`}
                    src={LadyStandingImage} alt="No Result User" />

                <p className={`${styles.NoResultMessage} text-center`}>You do not have any reviews yet</p>
            </div>
        </GridOverlay>
    );
}

const CustomPagination = (props) => {
    const { state, apiRef } = useGridSlotComponentProps();
    const classes = useStyles();

    return (
        <Grid container direction="row" justify="space-between" alignItems="center">
            <Grid item lg={4}>
            </Grid>

            <Grid item lg={4}>
                <div className={styles.TablePaginationContainer}>
                    <TablePagination
                        className={classes.root}
                        component="div"
                        count={state.rows.totalRowCount}
                        page={state.pagination.page}
                        onChangePage={(event, value) => {
                            apiRef.current.setPage(value);
                        }}
                        rowsPerPage={state.pagination.pageSize}
                        labelRowsPerPage={null}
                    />
                </div>
            </Grid>
            <Grid item lg={4}>
                <div className={styles.BackButtonContainer}>
                    <button className={styles.BackButton} onClick={() => props.onBackToPresentationListHandler()}>Back</button>
                </div>
            </Grid>

        </Grid >
    );
}

const ReviewsDatagrid = (props) => {

    const [selectedReview, setSelectedReview] = useState(null);

    const { presentationReviewsState } = props;
    const { loading } = presentationReviewsState;
    // const { success } = presentationReviewsState;
    // const { error } = presentationReviewsState;
    const { data } = presentationReviewsState;
    const sortedReviews = getSortedAscReviews(data);

    const columns = [
        {
            field: 'review',
            headerName: 'Review',
            flex: 0.66,
            renderCell: (params) => {
                const { id, userId, first_name, last_name, picture, stars, starsAvg, reviewComment, updated } = params.row;
                const review = new Review(id, userId, first_name, last_name, picture, stars, starsAvg, reviewComment, updated);

                return (
                    <div className={styles.ReviewInfoContainer}>
                        <div className={styles.DatagridProfileImage}>
                            <ProfilePicture userId={review.userId} className={styles.ProfilePicture} />
                        </div>
                        <div className={styles.ReviewInfo}>
                            <div className={styles.ReviewerDetails}>
                                <P inline={'true'} className={styles.FullName}>{review.fullName}</P> <P inline={'true'} className={styles.Date}>{review.updated}</P>
                            </div>
                            <p className={styles.ReviewDescription}>{review.reviewComment}</p>
                        </div>
                    </div>
                )
            },
            disableColumnMenu: true,
            disableColumnFilter: true,
            sortable: false
        },
        {
            field: 'rating',
            headerName: 'Rating',
            flex: 0.33,
            renderCell: (params) => {
                const { id, userId, first_name, last_name, picture, stars, starsAvg, reviewComment, updated } = params.row;
                const review = new Review(id, userId, first_name, last_name, picture, stars, starsAvg, reviewComment, updated);

                return (
                    <div className={styles.Reviews}>
                        <Rating
                            name="custom-rating-filter-operator"
                            placeholder="Filter value"
                            disabled
                            value={review.starsAvg}
                            precision={0.5}
                        />
                        <P inline={'true'} className={styles.ReviewsDetails} onClick={() => onViewReviewDetails(review)}>
                            See Details<i className={styles.ArrowDown}></i>
                        </P>
                    </div>
                )
            },
            disableColumnMenu: true,
            disableColumnFilter: true,
            sortable: false
        }
    ];

    const onViewReviewDetails = (review) => {
        setSelectedReview(review);
        props.OnReviewDetailsPageLoadedHandler(true);
    }

    const onBackToReviewsListHandler = () => {
        setSelectedReview(null);
        props.OnReviewDetailsPageLoadedHandler(false);
    }

    let containerUI = (
        <DataGrid
            rows={sortedReviews || []}
            columns={columns}
            rowHeight={145}
            // hideFooter={true}
            disableSelectionOnClick
            pageSize={6}
            autoPageSize
            pagination
            showCellRightBorder={false}
            showColumnRightBorder={false}
            loading={loading}
            components={{
                Pagination: CustomPagination,
                NoRowsOverlay: CustomNoRowsOverlay.bind(this, props)
            }}
            componentsProps={{
                pagination: { onBackToPresentationListHandler: props.onBackToPresentationListHandler },
            }}
        />
    );

    if (selectedReview) {
        containerUI = (<ReviewDetail
            review={selectedReview}
            onBackToReviewsListHandler={onBackToReviewsListHandler}
        />);
    }

    return (containerUI);
}

export default ReviewsDatagrid;