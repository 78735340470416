import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ButtonComp from '../ButtonComp/ButtonComp';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import {
    onInputHandleChange,
    setProfilepicture,
    handleUpload,
    getUserProfile,
    onStoreProfilePictureReset,
    onStoreProfileResetAction
} from '../../redux/action/Index';
import styles from './UserProfile.module.scss';
import { withStyles } from '@material-ui/core/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { withRouter } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import Loader from 'components/LoaderComp/LoaderComp';
import { CenterWrapper } from 'components/Shared/HOC';
import { MdAccountCircle } from 'react-icons/md';
import { WarningAlert } from 'components/Shared/Theme';
const profileImageBaseURL = (path) =>
    `${
        process.env.REACT_APP_PROFILE_IMAGES_URL
    }/${path}?${new Date().getTime()}`;

const useStyles = {
    root: {
        '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid rgba(149, 170, 201, 1)'
        }
    },
    input: {
        color: '#283E59',
        fontSize: '1rem',
        fontWeight: '600',
        fontStyle: 'normal'
    },
    inputLabel: {
        fontWeight: 'normal',
        fontSize: '20px',
        color: '#95AAC9'
    }
};

class UserProfile extends Component {
    constructor() {
        super();
        this.onChange = this.onChange.bind(this);
        this.state = {
            src: '',
            fileName: '',
            profileImageError: false
        };
    }

    componentDidMount() {
        this.props.getUserProfile();
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            state: { initialData }
        } = this.props;
        const { updateUserProfile } = initialData;
        if (updateUserProfile.success) {
            this.props.history.push('/presentations/dashboard/presentations');
        }
    }

    componentWillUnmount() {
        this.props.onStoreProfilePictureReset();
        this.props.onStoreProfileResetAction();
    }

    onChange(e) {
        const fileName = e.target.files[0].name;
        let reader = new FileReader();
        reader.onload = (e) => {
            this.setState({
                src: reader.result,
                fileName: fileName,
                profileImageError: false
            });
        };
        reader.readAsDataURL(e.target.files[0]);
        this.props.setProfilepicture(e.target.files[0]);
    }

    onProfileImageErrorHandler = () => {
        this.setState({ profileImageError: true });
    };

    render() {
        const materialStyles = this.props.classes;
        const { profileImageError } = this.state;
        const {
            state: { initialData }
        } = this.props;

        const { updateUserProfile, userProfileImage } = initialData;
        let profileImageUI = null;

        if (initialData.userProfile.data.picture && !this.state.src) {
            profileImageUI = (
                <img
                    className={`profile-image ${styles.ProfileImage}`}
                    src={profileImageBaseURL(
                        initialData.userProfile.data.picture
                    )}
                    alt=""
                    onError={this.onProfileImageErrorHandler}
                />
            );
        } else {
            profileImageUI = (
                <img
                    className={`profile-image ${styles.ProfileImage}`}
                    src={this.state.src}
                    alt=""
                    onError={this.onProfileImageErrorHandler}
                />
            );
        }

        let containerUI = (
            <>
                <Grid container justify="center">
                    <Grid item xs={8} lg={8}>
                        <Grid container className={styles.Container}>
                            {userProfileImage.error ? (
                                <Grid item sm={12} md={12} lg={12}>
                                    <div
                                        className={`${styles.UserProfileImageErrorContainer}`}>
                                        <WarningAlert
                                            message={
                                                'Error occured while uploading user profile image.'
                                            }
                                        />
                                    </div>
                                </Grid>
                            ) : null}

                            {updateUserProfile.error ? (
                                <Grid item sm={12} md={12} lg={12}>
                                    <div
                                        className={`${styles.UserProfileErrorContainer}`}>
                                        <WarningAlert
                                            message={
                                                'Failed to update user profile.'
                                            }
                                        />
                                    </div>
                                </Grid>
                            ) : null}

                            <Grid item xs={12}>
                                <h1 className={styles.Title}>Profile</h1>
                            </Grid>

                            <Grid item xs={12}>
                                <span className={styles.SubTitle}>
                                    Personal Information
                                </span>
                                <span className={styles.SubTitleInfo}>
                                    This information is public
                                </span>
                            </Grid>

                            <Grid item xs={2}>
                                <div className={styles.FieldContainer}>
                                    <TextField
                                        required
                                        variant="outlined"
                                        placeholder="Title"
                                        className=""
                                        value={
                                            initialData.userProfile.data
                                                .title || ''
                                        }
                                        onChange={(event) =>
                                            this.props.onInputHandleChange(
                                                event.target.value,
                                                'title'
                                            )
                                        }
                                        fullWidth
                                        classes={{
                                            root: materialStyles.root
                                        }}
                                        InputProps={{
                                            className: materialStyles.input
                                        }}
                                        InputLabelProps={{
                                            className: materialStyles.inputLabel
                                        }}
                                    />
                                    <span className={styles.Label}>
                                        Honorific
                                    </span>
                                </div>
                            </Grid>
                            <Grid item xs={5}>
                                <div className={styles.FieldContainer}>
                                    <TextField
                                        required
                                        variant="outlined"
                                        placeholder="Legal or Nickname"
                                        className=""
                                        value={
                                            initialData.userProfile.data
                                                .first_name || ''
                                        }
                                        onChange={(event) =>
                                            this.props.onInputHandleChange(
                                                event.target.value,
                                                'first_name'
                                            )
                                        }
                                        fullWidth
                                        classes={{
                                            root: materialStyles.root
                                        }}
                                        InputProps={{
                                            className: materialStyles.input
                                        }}
                                        InputLabelProps={{
                                            className: materialStyles.inputLabel
                                        }}
                                    />
                                    <span className={styles.Label}>
                                        First Name
                                    </span>
                                </div>
                            </Grid>
                            <Grid item xs={5}>
                                <div className={styles.FieldContainer}>
                                    <TextField
                                        required
                                        variant="outlined"
                                        placeholder="Legal Name"
                                        className=""
                                        value={
                                            initialData.userProfile.data
                                                .last_name || ''
                                        }
                                        onChange={(event) =>
                                            this.props.onInputHandleChange(
                                                event.target.value,
                                                'last_name'
                                            )
                                        }
                                        classes={{
                                            root: materialStyles.root
                                        }}
                                        InputProps={{
                                            className: materialStyles.input
                                        }}
                                        InputLabelProps={{
                                            className: materialStyles.inputLabel
                                        }}
                                    />
                                    <span className={styles.Label}>
                                        Last Name
                                    </span>
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <div className={styles.FieldContainer}>
                                    <TextField
                                        variant="outlined"
                                        placeholder="Type here..."
                                        className=""
                                        value={
                                            initialData.userProfile.data
                                                .occupation || ''
                                        }
                                        onChange={(event) =>
                                            this.props.onInputHandleChange(
                                                event.target.value,
                                                'occupation'
                                            )
                                        }
                                        classes={{
                                            root: materialStyles.root
                                        }}
                                        InputProps={{
                                            className: materialStyles.input
                                        }}
                                        InputLabelProps={{
                                            className: materialStyles.inputLabel
                                        }}
                                    />
                                    <span className={styles.Label}>
                                        Occupation
                                    </span>
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <div className={styles.FieldContainer}>
                                    <TextField
                                        variant="outlined"
                                        placeholder="You worked for it. Show it off."
                                        className=""
                                        value={
                                            initialData.userProfile.data
                                                .credentials || ''
                                        }
                                        onChange={(event) =>
                                            this.props.onInputHandleChange(
                                                event.target.value,
                                                'credentials'
                                            )
                                        }
                                        classes={{
                                            root: materialStyles.root
                                        }}
                                        InputProps={{
                                            className: materialStyles.input
                                        }}
                                        InputLabelProps={{
                                            className: materialStyles.inputLabel
                                        }}
                                    />
                                    <span className={styles.Label}>
                                        Credentials{' '}
                                        <InfoOutlinedIcon
                                            style={{ marginLeft: '10px' }}
                                            data-tip
                                            data-for="credentials-tip"
                                        />
                                    </span>

                                    <ReactTooltip
                                        id="credentials-tip"
                                        place="right"
                                        effect="solid"
                                        backgroundColor="white"
                                        textColor="#3C7BB3"
                                        border
                                        borderColor="#A3C0DB">
                                        Seperate each credentials with a comma.
                                        <br />
                                        (Example: MSN, RN, FNP-BC, etc.)
                                    </ReactTooltip>
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <div className={styles.FieldContainer}>
                                    <div
                                        className={
                                            styles.UploadProfileImageContainer
                                        }>
                                        {!profileImageError ? (
                                            profileImageUI
                                        ) : (
                                            <MdAccountCircle
                                                color="F9B041"
                                                size={'3em'}
                                            />
                                        )}

                                        <label
                                            htmlFor="file-upload"
                                            className={`custom-image-upload-label ${styles.UploadImageLabel}`}>
                                            Upload image
                                            <input
                                                type="file"
                                                style={{ display: 'none' }}
                                                accept="image/*"
                                                id="file-upload"
                                                onChange={this.onChange}
                                            />
                                        </label>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <div className={styles.FieldContainer}>
                                    <TextField
                                        multiline
                                        rows="8"
                                        cols="50"
                                        id="w3review"
                                        variant="outlined"
                                        placeholder="Brag about yourself. This will be shown on your educator page."
                                        className=""
                                        value={
                                            initialData.userProfile.data
                                                .about_me || ''
                                        }
                                        onChange={(event) =>
                                            this.props.onInputHandleChange(
                                                event.target.value,
                                                'about_me'
                                            )
                                        }
                                        classes={{
                                            root: materialStyles.root
                                        }}
                                        InputProps={{
                                            className: materialStyles.input
                                        }}
                                        InputLabelProps={{
                                            className: materialStyles.inputLabel
                                        }}
                                    />
                                    <span className={styles.Label}>
                                        About me
                                    </span>
                                </div>
                            </Grid>

                            <Grid item xs={12} style={{ marginTop: '20px' }}>
                                <span className={styles.SubTitle}>
                                    Social media information
                                </span>
                                <span className={styles.SubTitleInfo}>
                                    This information is not public but helps
                                    with search rankings
                                </span>
                            </Grid>

                            <Grid item xs={6}>
                                <div className={styles.FieldContainer}>
                                    <TextField
                                        required
                                        variant="outlined"
                                        placeholder="Vouch for it"
                                        className=""
                                        value={
                                            initialData.userProfile.data
                                                .linkedin_url || ''
                                        }
                                        onChange={(event) =>
                                            this.props.onInputHandleChange(
                                                event.target.value,
                                                'linkedin_url'
                                            )
                                        }
                                        classes={{
                                            root: materialStyles.root
                                        }}
                                        InputProps={{
                                            className: materialStyles.input
                                        }}
                                        InputLabelProps={{
                                            className: materialStyles.inputLabel
                                        }}
                                    />
                                    <span className={styles.Label}>
                                        LinkedIn
                                    </span>
                                </div>
                            </Grid>

                            <Grid item xs={6}>
                                <div className={styles.FieldContainer}>
                                    <TextField
                                        required
                                        variant="outlined"
                                        placeholder="Do you tweet?"
                                        className=""
                                        value={
                                            initialData.userProfile.data
                                                .twitter_url || ''
                                        }
                                        onChange={(event) =>
                                            this.props.onInputHandleChange(
                                                event.target.value,
                                                'twitter_url'
                                            )
                                        }
                                        classes={{
                                            root: materialStyles.root
                                        }}
                                        InputProps={{
                                            className: materialStyles.input
                                        }}
                                        InputLabelProps={{
                                            className: materialStyles.inputLabel
                                        }}
                                    />
                                    <span className={styles.Label}>
                                        Twitter
                                    </span>
                                </div>
                            </Grid>

                            <Grid item xs={6}>
                                <div className={styles.FieldContainer}>
                                    <TextField
                                        required
                                        variant="outlined"
                                        placeholder="Social Proof?"
                                        className=""
                                        value={
                                            initialData.userProfile.data
                                                .fb_url || ''
                                        }
                                        onChange={(event) =>
                                            this.props.onInputHandleChange(
                                                event.target.value,
                                                'fb_url'
                                            )
                                        }
                                        classes={{
                                            root: materialStyles.root
                                        }}
                                        InputProps={{
                                            className: materialStyles.input
                                        }}
                                        InputLabelProps={{
                                            className: materialStyles.inputLabel
                                        }}
                                    />
                                    <span className={styles.Label}>
                                        Facebook
                                    </span>
                                </div>
                            </Grid>

                            <Grid item xs={6}>
                                <div className={styles.FieldContainer}>
                                    <TextField
                                        required
                                        variant="outlined"
                                        placeholder="Other digital avenues?"
                                        className=""
                                        value={
                                            initialData.userProfile.data
                                                .website_url || ''
                                        }
                                        onChange={(event) =>
                                            this.props.onInputHandleChange(
                                                event.target.value,
                                                'website_url'
                                            )
                                        }
                                        classes={{
                                            root: materialStyles.root
                                        }}
                                        InputProps={{
                                            className: materialStyles.input
                                        }}
                                        InputLabelProps={{
                                            className: materialStyles.inputLabel
                                        }}
                                    />
                                    <span className={styles.Label}>
                                        Website
                                    </span>
                                </div>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                className={styles.SaveActionContainer}>
                                <div className={styles.SaveAction}>
                                    <ButtonComp
                                        name={'Save'}
                                        handleClick={() => {
                                            this.props.handleUpload('save');
                                        }}
                                        btnDisable={
                                            initialData.userProfile.data
                                                .first_name !== '' &&
                                            initialData.userProfile.data
                                                .last_name !== '' &&
                                            initialData.userProfile.data
                                                .occupation !== ''
                                                ? false
                                                : true
                                        }
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );

        if (
            initialData.userProfile.loading ||
            updateUserProfile.loading ||
            userProfileImage.loading
        ) {
            containerUI = (
                <CenterWrapper>
                    <Loader />
                </CenterWrapper>
            );
        }

        return <>{containerUI}</>;
    }
}

function mapStateToProps(state) {
    return {
        state: state.reducer
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            onInputHandleChange,
            setProfilepicture,
            handleUpload,
            getUserProfile,
            onStoreProfilePictureReset,
            onStoreProfileResetAction
        },
        dispatch
    );
}

export default withStyles(useStyles)(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(UserProfile))
);
