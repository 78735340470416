import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { getPresentations } from '../../redux/action/Index';
import { onTitleChange } from 'redux/action/Index';
import { onInputHandleChange } from 'redux/action/Index';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import styles from './PresentationsTab.module.scss';
import { commonUTCToLocalDateFormat } from 'utils/date';
import { IoMdShareAlt } from 'react-icons/io';
import { RiPencilLine } from 'react-icons/ri';
import { AiOutlineEye } from 'react-icons/ai';
import { GridOverlay } from '@material-ui/data-grid';
import defaultPresentationThumbnailImage from '../../assets/images/samples/sample-datagrid-image.png';
import ReactModalAdapter from 'components/Shared/Helpers/ReactModalAdapter';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import styled from 'styled-components';
import { useAtom } from 'jotai';
import { zoneInfoAtom } from 'containers/container';

const presentationThumbnailImage = (id) =>
    `${process.env.REACT_APP_PRESENTATION_THUMBNAILS_URL}/thumbnails/presentations/${id}/thumbnail.jpeg`;

const StyleSharedModal = styled(ReactModalAdapter)`
    &.SharePresentationModal__overlay {
        transition: opacity 2000ms ease-in-out;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        inset: 0px;
        z-index: 50;
        --bg-opacity: 0.3;
        background-color: rgba(0, 0, 0, var(--bg-opacity));
        transition: opacity 2000ms ease-in-out 0s;
    }
    &.SharePresentationModal__content {
        max-width: 1280px;
        position: absolute;
        inset: 0px;
        display: flex;
        flex-direction: column;
        -webkit-box-pack: center;
        justify-content: center;
        align-items: flex-start;
        border-radius: 0.5rem;
        outline: transparent solid 2px;
        outline-offset: 2px;
        width: 33.3333%;
        max-height: 50%;
        margin: auto;
        --bg-opacity: 1;
        background-color: rgba(255, 255, 255, var(--bg-opacity));
        place-self: flex-start start;
        padding-left: 2.5rem;
        padding-right: 2.5rem;
        padding-bottom: 2.5rem;
        height: fit-content;
    }
    .content {
    }
`;

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiDataGrid-root': {
            border: 0
        },
        '& .MuiDataGrid-footer': {
            justifyContent: 'center'
        },
        '& .MuiDataGrid-columnHeader': {
            outline: 'none !important'
        },
        '& .MuiDataGrid-columnHeaderTitle': {
            color: '#283E59',
            fontWeight: 300,
            fontSize: 12,
            outline: 'none !important'
        },
        '& .MuiDataGrid-columnHeaderTitleContainer': {
            justifyContent: 'center',
            outline: 'none !important'
        },
        '& .MuiDataGrid-cell--withRenderer': {
            justifyContent: 'center !important',
            outline: 'none !important'
        }
    },
    inputSelect: {
        '& .MuiOutlinedInput-input': {
            padding: '10.5px 14px'
        },
        color: '#283E59',
        fontSize: '18px',
        fontWeight: 600
    }
}));

const DataTable = (props) => {
    const rows = props.rows || [];
    const { columns } = props;
    const classes = useStyles();

    return (
        <div style={{ height: 560, width: '100%' }} className={classes.root}>
            <DataGrid
                rows={rows}
                columns={columns}
                rowHeight={75}
                pageSize={6}
                autoPageSize
                showCellRightBorder={false}
                showColumnRightBorder={false}
                loading={props.loading}
                disableSelectionOnClick
                components={{
                    NoRowsOverlay: CustomNoRowsOverlay
                }}
            />
        </div>
    );
};

const CustomNoRowsOverlay = () => {
    const classes = useStyles();

    return (
        <GridOverlay className={classes.root}>
            <div className="container mx-auto -lg:px-8 -md:px-4 flex flex-col justify-center items-center max-w-screen-lg text-center">
                <img
                    className={`my-12`}
                    src={require('../../assets/images/no-result/lady-standing.svg')}
                    alt="No Result User"
                />

                <p className={`${styles.NoResultMessage} text-center`}>
                    You do not have any modules yet
                </p>
            </div>
        </GridOverlay>
    );
};

const PresentationsTab = (props) => {
    const history = useHistory();
    // eslint-disable-next-line no-unused-vars
    const classes = useStyles();
    const dispatch = useDispatch();
    const [shareLinkCopied, setShareLinkCopied] = useState(false);
    const [isShareModalActive, setIsShareModalActive] = useState(false);
    const [presentationIdToShare, setPresentationIdToShare] = useState(null);
    const [zoneInfo] = useAtom(zoneInfoAtom);

    useEffect(() => {
        props.getPresentations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = [
        {
            field: 'id',
            hide: true
        },
        {
            field: 'title',
            headerName: 'Title',
            flex: 1,
            renderCell: (params) => (
                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    style={{ padding: 5 }}
                    alignItems="center">
                    <Grid item lg={3}>
                        <div className={styles.DatagridPresentationImage}>
                            <img
                                style={{
                                    width: 'auto',
                                    height: '100%',
                                    maxWidth: '100%',
                                    maxHeight: 65
                                }}
                                src={presentationThumbnailImage(params.row.id)}
                                onError={(e) => {
                                    e.target.onError = null;
                                    e.target.src =
                                        defaultPresentationThumbnailImage;
                                }}
                                alt="Datagrid Display"></img>
                        </div>
                    </Grid>
                    <Grid item lg={9}>
                        <Box component="div">
                            <span
                                className={`${styles.PresentationsColumnSpan} ${styles.PresentationsColumnSpanTitle}`}>
                                {params.row.title}
                            </span>
                        </Box>
                    </Grid>
                </Grid>
            ),
            disableColumnMenu: true,
            disableColumnFilter: true,
            sortable: false
        },
        {
            field: 'createDate',
            headerName: 'Date',
            width: 150,
            renderCell: (params) => (
                <div className={styles.Reviews}>
                    <span className={styles.ReviewsDetails}>
                        {params.row.createdDate
                            ? commonUTCToLocalDateFormat(params.row.createdDate)
                            : '-'}
                    </span>
                </div>
            ),
            disableColumnMenu: true,
            disableColumnFilter: true,
            sortable: false
        },
        {
            field: 'duration',
            headerName: 'Duration',
            width: 150,
            renderCell: (params) => (
                <span className={styles.DurationSpan}>
                    {params.row.videoDuration
                        ? params.row.videoDuration.slice(0, 5)
                        : '-'}
                </span>
            ),
            disableColumnMenu: true,
            disableColumnFilter: true,
            sortable: false
        },
        {
            field: 'reviews',
            headerName: 'Reviews',
            width: 100,
            renderCell: (params) => (
                <span className={styles.ViewsSpan}>
                    {params.row.reviewCount ? params.row.reviewCount : '-'}
                </span>
            ),
            disableColumnMenu: true,
            disableColumnFilter: true,
            sortable: false
        },
        {
            field: 'views',
            headerName: 'Views',
            width: 100,
            renderCell: (params) => (
                <span className={styles.ViewsSpan}>
                    {params.row.viewCount ? params.row.viewCount : '-'}
                </span>
            ),
            disableColumnMenu: true,
            disableColumnFilter: true,
            sortable: false
        },
        {
            field: 'replay',
            headerName: 'Replay',
            width: 180,
            renderCell: (params) => (
                <div className={styles.PresentationColumnReplayContainer}>
                    <AiOutlineEye
                        title="Preview"
                        size={'2em'}
                        color={'rgba(0, 0, 0, 0.6)'}
                        className={`${styles.ViewPresentation}`}
                        aria-hidden="true"
                        onClick={() => {
                            return onViewPresentation(params.row.id);
                        }}
                    />

                    <RiPencilLine
                        title="Edit"
                        size={'2em'}
                        color={'rgba(0, 0, 0, 0.6)'}
                        className={`${styles.EditPresentation}`}
                        aria-hidden="true"
                        onClick={() => {
                            dispatch(onTitleChange(params.row.title || ''));
                            dispatch(
                                onInputHandleChange(
                                    params.row.description || '',
                                    'description'
                                )
                            );
                            onEditPresentation(
                                params.row.id,
                                params.row.title,
                                params.row.description
                            );
                        }}
                    />

                    {/*<MdOutlineQuestionAnswer
                        size={'2em'}
                        color={'rgba(0, 0, 0, 0.6)'}
                        className={`${styles.EditFeedbackForm}`}
                    />*/}

                    <IoMdShareAlt
                        title="Share"
                        className={`${styles.SharePresentation}`}
                        size={'2em'}
                        color={'rgba(0, 0, 0, 0.6)'}
                        onClick={() => {
                            setShareLinkCopied(false);
                            setIsShareModalActive(true);
                            setPresentationIdToShare(params.row.id);
                        }}
                    />
                </div>
            ),
            disableColumnMenu: true,
            disableColumnFilter: true,
            sortable: false
        }
    ];

    const onViewPresentation = (id) => {
        history.push('/presentation/view/' + id);
    };

    const onEditPresentation = (id, videoTitle, description) => {
        history.push({
            pathname: '/presentation/edit/' + id,
            search:
                '?' +
                new URLSearchParams({ videoTitle, description }).toString()
        });
    };

    //const viewsReportDurations = [{ label: 'Most Views', value: 'mostViews' }];

    const getSharePresentationLink = () => {
        if (zoneInfo) {
            return `${process.env.REACT_APP_ROOT_URL}/presentation/${zoneInfo}/${presentationIdToShare}`;
        } else {
            return `${process.env.REACT_APP_ROOT_URL}/presentation/${presentationIdToShare}`;
        }
    };

    const copyLinkToSharePresentation = () => {
        setShareLinkCopied(true);
    };

    const closeShareModal = () => {
        setIsShareModalActive(false);
        setPresentationIdToShare(null);
    };

    const shareModal = (
        <StyleSharedModal
            closeTimeoutMS={300}
            className={`SharePresentationModal`}
            isOpen={isShareModalActive}
            onRequestClose={() => {
                closeShareModal();
            }}
            shouldCloseOnOverlayClick={true}
            contentClass={styles.contentClass}
            overlayClass={styles.overlayClass}>
            <Grid container direction="row" justify="space-between">
                <Grid item lg={12} sm={12}>
                    <div className={`${styles.ShareModalHeaderAction}`}>
                        <h1 className={`${styles.ShareModalHeader}`}>
                            Share Module
                        </h1>
                        <IoMdCloseCircleOutline
                            className="cursor-pointer"
                            size={'1.8em'}
                            color={'#283E59'}
                            onClick={() => {
                                setIsShareModalActive(false);
                            }}
                        />
                    </div>
                </Grid>

                <Grid item lg={12} sm={12}></Grid>

                <Grid item lg={12} sm={12}>
                    <div
                        className={`flex items-center justify-between space-x-5 mt-3 ${styles.IconColor}`}>
                        <div className="w-full justify-center text-center mt-5">
                            <div
                                className={`p-3 w-full flex justify-between ${styles.CopyTextContainer}`}>
                                <span className={`${styles.CopyText}`}>
                                    {getSharePresentationLink()}
                                </span>
                                <CopyToClipboard
                                    text={getSharePresentationLink()}
                                    onCopy={() =>
                                        copyLinkToSharePresentation()
                                    }>
                                    <button
                                        className={`outline-none ${styles.CopyButton}`}>
                                        Copy
                                    </button>
                                </CopyToClipboard>
                            </div>
                        </div>
                        {shareLinkCopied ? (
                            <div className={`${styles.LinkCopyMessage}`}>
                                Link copied!
                            </div>
                        ) : null}
                    </div>
                </Grid>
            </Grid>
        </StyleSharedModal>
    );

    let containerUI = (
        <Grid
            item
            lg={12}
            sm={12}
            className={`${styles.PresentationsContainer}`}>
            <Grid container direction="row" justify="space-between">
                <Grid item lg={8} sm={6}></Grid>

                {/*<Grid item lg={4} sm={6} style={{ textAlign: 'end' }}>
                    <FormControl variant="outlined" style={{ width: '50%' }}>
                        <Select
                            native
                            // value={state.age}
                            // onChange={handleChange}
                            className={classes.inputSelect}
                            inputProps={{
                                name: 'viewsReportDurations',
                                id: 'outlined-age-native-simple'
                            }}
                            style={{
                                color: '#283E59',
                                fontSize: '18px',
                                fontWeight: 600
                            }}>
                            {viewsReportDurations.map(
                                (viewsReportDuration, index) => (
                                    <option
                                        key={index}
                                        value={viewsReportDuration.value}>
                                        {viewsReportDuration.label}
                                    </option>
                                )
                            )}
                        </Select>
                    </FormControl>
                                </Grid>*/}

                <Grid item lg={12} sm={12} style={{ marginTop: 10 }}>
                    <DataTable
                        rows={props.presentationsData.data}
                        loading={props.presentationsData.loading}
                        columns={columns}
                    />
                </Grid>
            </Grid>
        </Grid>
    );

    return (
        <Grid container direction="row" justify="center">
            <Grid item lg={12} sm={12}>
                <p className={styles.Title}>Modules</p>
            </Grid>

            {containerUI}
            {shareModal}
        </Grid>
    );
};

const mapStateToProps = (state) => {
    return {
        state: state.reducer,
        ApplicationText: state.ApplicationText,
        presentationsData: state.reducer.initialData.presentationsData
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getPresentations
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(PresentationsTab);
