import { commonUTCToLocalDateFormat } from 'utils/date';

export default class Review {
    constructor(id, userId, first_name, last_name, picture, stars, starsAvg, reviewComment, updated) {
        this.id = id;
        this.userId = userId;
        this.first_name = first_name;
        this.last_name = last_name;
        this.fullName = first_name ? (last_name ? `${first_name} ${last_name}` : first_name) : '-';
        this.picture = picture;
        this.stars = stars;
        this.starsAvg = starsAvg ? +starsAvg : 0;
        this.reviewComment = reviewComment;
        this.updated = updated ? commonUTCToLocalDateFormat(updated) : '-';
    }
}