import React, { useEffect, useMemo, useState } from 'react';
import LoaderComp from '../LoaderComp/LoaderComp';
import { SizeMe } from 'react-sizeme';
import { useMediaQuery } from 'react-responsive';
import { Document, Page } from 'react-pdf';

function PDFViewComponent(props) {
    const [numPages, setNumPages] = useState(null);
    const [loadingPdfError, setLoadingPdfError] = useState(null);
    const [sourceOfFileError, setSourceOfFileError] = useState(null);
    const [noDataError, setNoDataError] = useState(null);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const onLoadError = (error) => {
        setLoadingPdfError(error);
    };

    const onSourceError = (error) => {
        setSourceOfFileError(error);
    };

    const { pageNumber, pdfData } = props.state.initialData;

    useEffect(() => {
        if (!pdfData.url) {
            setNoDataError(true);
        } else {
            setNoDataError(false);
        }
    }, [pdfData.url]);

    const filePath = useMemo(() => pdfData.url, [pdfData.url]);

    let pdfCanvasHeight = 450;

    const isDesktopOrLaptopHD = useMediaQuery({
        query: '(max-device-width: 1280px)'
    });

    const isDesktopOrLaptopHDPlus = useMediaQuery({
        query: '(max-device-width: 1366px)'
    });

    const isDesktopOrLaptopHiDpi = useMediaQuery({
        query: '(max-device-width: 1440px)'
    });

    if (isDesktopOrLaptopHD) {
        pdfCanvasHeight = 350;
    } else if (isDesktopOrLaptopHDPlus) {
        pdfCanvasHeight = 380;
    } else if (isDesktopOrLaptopHiDpi) {
        pdfCanvasHeight = 393;
    }

    return (
        <div className="detauls-content">
            <div style={{ height: pdfCanvasHeight }}>
                {numPages == null &&
                !loadingPdfError &&
                !sourceOfFileError &&
                !noDataError ? (
                    <div className="pdf-page-loader">
                        <LoaderComp />
                    </div>
                ) : null}
                <SizeMe>
                    {({ size }) => (
                        <Document
                            file={filePath}
                            noData="No File Selected"
                            loading=""
                            onLoadSuccess={onDocumentLoadSuccess}
                            onLoadError={onLoadError}
                            onSourceError={onSourceError}>
                            <Page
                                loading={null}
                                size="A4"
                                pageNumber={pageNumber}
                                height={
                                    size.height
                                        ? size.height / 2
                                        : pdfCanvasHeight
                                }
                                scale={1}
                                orientation="landscape"
                            />
                        </Document>
                    )}
                </SizeMe>
            </div>
            <div className="recordingpage__pageInfo">
                <img
                    onClick={() => {
                        if (pageNumber > 1) {
                            props.onPageChangeClick('previous', numPages);
                            if (props.isRecordingEnabled) {
                                props.onPageChangeTimeStampRecord(
                                    pageNumber - 1
                                );
                            }
                        }
                    }}
                    alt="left"
                    className="recordingpage__pageInfo__left"
                    src={require('../../assets/right.svg')}
                />
                <p className="recordingpage__pageInfo__pageNumber">
                    {pageNumber}/{numPages}
                </p>
                <img
                    onClick={() => {
                        if (pageNumber < numPages) {
                            props.onPageChangeClick('next', numPages);
                            if (props.isRecordingEnabled) {
                                props.onPageChangeTimeStampRecord(
                                    pageNumber + 1
                                );
                            }
                        }
                    }}
                    alt="right"
                    className="recordingpage__pageInfo__right"
                    src={require('../../assets/left.svg')}
                />
            </div>
        </div>
    );
}

export default PDFViewComponent;
