import { Link } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import '../../style/components/Footer.scss';

const FooterComp = (props) => {
    const { initialData } = props.state;

    return (
        <div className="footer__new">
            <p>Copyright &copy; 2024 Beaker Health. Inc.</p>
            <ul className="footer__new__right">
                <li>
                    <Link to="/">Terms & Conditions</Link>
                </li>
                <li>
                    <Link to="/">Privacy Policy</Link>
                </li>
                <li>
                    <a href="https://www.facebook.com/Beaker-Health-109159111266555">
                        <img alt="img" src={initialData.assets.facebook}></img>
                    </a>
                </li>
                <li>
                    <a href="https://twitter.com/beaker_health">
                        <img alt="img" src={initialData.assets.twitter}></img>
                    </a>
                </li>
                <li>
                    <a href="https://www.linkedin.com/company/beakerhealth">
                        <img alt="img" src={initialData.assets.linkedin}></img>
                    </a>
                </li>
            </ul>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        state: state.reducer,
        ApplicationText: state.ApplicationText
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(FooterComp);
